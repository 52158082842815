function randomChars() {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  const length = 8;
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
function randomExract(chars) {
  let result = chars.substr(chars.length - 8);
  return result;
}
function randomRemove(chars) {
  let result = chars.slice(0, -8);
  return result;
}
export default {
  randomChars,
  randomExract,
  randomRemove,
};
