<template>
  <div class="vi-hero">
    <vi-header v-if="$route.path == '/' || $route.path == '/search-schools'"/>

    <section
      id="hero"
      class="align-items-center pl-4 pr-4 overflow-hidden schbg"
    >
      <div class="container-fluid">
        <div
          class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
        >
          <div
            class="wow web-fadeIn"
            data-wow-delay="0.1s"
            style=" -webkit-animation-delay: 0.2s; -moz-animation-delay: 0.2s; animation-delay: 0.2s;"
          >
            <div class="web-ellipsecont wow web-fadeInRight">
              <h1>Discover Best Schools in your Place...</h1>
              <span style="display:inline-block;margin-bottom:20px;"
                >Search Schools, Play & Pre-Schools in India</span
              >
              <div class="web-hmtab">
                <button
                  class="hmtablinks active"
                  @click="openhmSearch($event, 'sSearch')"
                >
                  School Search
                </button>
               
              </div>
              <div id="sSearch" class="web-hmtabcontent" style="display:block">
                <div class="home-search-form">
                  <div class="form-row">
                    <div class="form-group input-group col-sm-12 pr-md-3">
                      <span class="web-has-float-label">
                        <v-select
                          placeholder="Search School Name"
                          class="form-control web-form-input"
                          id="autocomplete"
                          label="accountName"
                          name="schoolName"
                          v-model="sname"
                          :options="accountList"
                          @search="getSchoolList"
                          @input="selectedOption"
                        >
                          <span v-if="!showOption" style="display: none !important;" slot="no-options"></span>
                        </v-select>

                        <label for="schoolName">School Name <span class="required">*</span></label>
                      </span>
                    </div>
                    <div class="form-group input-group col-sm-12 pr-md-3">
                      <span class="web-has-float-label">
                        <vue-google-autocomplete
                          ref="address"
                          id="map"
                          v-on:inputChange="clearAddress"
                          class="form-control form-input"
                          placeholder="Search School By Location"
                          country="IN"
                          types=""
                          v-on:placechanged="getAddressData"
                        >
                        </vue-google-autocomplete>
                        <label for="countryid">Search Address</label>
                      
                      </span>
                    </div>
                  </div>
                 
                  <div style="text-align:center">
                    <button
                      type="submit"
                      class="web-custbutton mt-3"
                      @click="submitSearch"
                    >
                      Search
                    </button>

                    <button class="web-cancelbutton mt-3 ml-2" @click="reset">
                      Reset
                    </button>
                  </div>
                </div>
                <vi-spinner
                  v-if="viLoader"
                  text="Loading..."
                  textColor="vi-brand-color"
                  class="flex-fill h-100 vi-fs12"
                  style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </section>
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import Select from "vue-select";
import "vue-select/dist/vue-select.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { eventBus } from "../../event-bus";

export default {
  name: "vi-home-hero-search",
  components: {
    ViSpinner,
    VueGoogleAutocomplete,
    "v-select": Select,
    "vi-header" : () => import("../../views/ViHeader.vue")

  },
  data() {
    return {
      sname: "",
      scategory: null,
      slocation: null,
      state: null,
      city: null,
      district: null,
      street: null,
      serviceError: null,
      viLoader: null,
      autocomplete: "",
      address: "",
      stateList: {
        AN: "Andaman and Nicobar Islands",
        AP: "Andhra Pradesh",
        AR: "Arunachal Pradesh",
        AS: "Assam",
        BR: "Bihar",
        CH: "Chandigarh",
        CT: "Chhattisgarh",
        DN: "Dadra and Nagar Haveli",
        DD: "Daman and Diu",
        DL: "Delhi",
        GA: "Goa",
        GJ: "Gujarat",
        HR: "Haryana",
        HP: "Himachal Pradesh",
        JK: "Jammu and Kashmir",
        JH: "Jharkhand",
        KA: "Karnataka",
        KL: "Kerala",
        LD: "Lakshadweep",
        MP: "Madhya Pradesh",
        MH: "Maharashtra",
        MN: "Manipur",
        ML: "Meghalaya",
        MZ: "Mizoram",
        NL: "Nagaland",
        OR: "Odisha",
        PY: "Puducherry",
        PB: "Punjab",
        RJ: "Rajasthan",
        SK: "Sikkim",
        TN: "Tamil Nadu",
        TG: "Telangana",
        TR: "Tripura",
        UP: "Uttar Pradesh",
        UT: "Uttarakhand",
        WB: "West Bengal",
      },
      skip: 0,
      limit: 7,
      showOption: false,
      countAll: true,
      lastPageNumber: 1,
      accountList: [],
      showServices: false
    };
  },

  mounted() {
  
    document.getElementById("map").innerHTML = document.getElementById(
      "map"
    ).innerHTML;
    this.$refs.address.focus();
  },

 
  methods: {
   
    clearAddress(addressData, placeResultData, id) {
      if (addressData.newVal == "" || addressData.newVal == null) {
        (this.street = null),
          (this.state = null),
          (this.district = null),
          (this.city = null);
      }
    },

    async getSchoolList(search,loading) {
     
      if(search && search != '' && search != null && search != null) {
        
        const response = await ViService.viXGet(
        `/common/allschool?skip=${this.skip}&limit=${this.limit}&search=${search}&isCount=${this.countAll}`
      );
      if (response.isSuccess) {
        const resultData = secureUI.secureGet(response.data);
        this.accountList = resultData.accountList;
        if(resultData.accountList.length == 0){
           this.showOption = true
        }
        if (this.countAll) {
          this.totalCount = resultData.totalCount;
          this.lastPageNumber = Math.ceil(this.totalCount / this.limit);
        }
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
      } else {
        this.accountList = [];
         this.showOption = false
         
      }
      
    },

    selectedOption(){
         console.log("value");
    },

    openhmSearch(evt, srcName) {},

    clear() {
      this.$toasted.clear();
      this.scategory = null;
      this.slocation = null;
      this.serviceError = null;
      this.$refs.address.$refs.autocomplete.value = "";
    },

    submitSearch() {
        this.$toasted.clear();
        
       // if(this.sname != null && this.sname != "" && this.sname != undefined ){
          let requestBody = {
          schoolName: this.sname.accountName,
          category: this.scategory,
          state: this.state,
          city: this.city,
          district: this.district,
          street: this.street,
        };
        this.viLoader = true;
        ViService.viPost("/app/school", requestBody)
          .then((res) => {
            this.viLoader = null;
            if (res.isSuccess) {
              let data = secureUI.secureGet(res.data);
              if (data && data.length > 0) {
                this.$store.dispatch("setSchoolList", data);
                this.$router.push({
                  name: "schools",
                });
              } else {
                this.$toasted.error("Records not found");
              }
            } else {
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            this.viLoader = null;
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
          });
        // }else{
        //   this.$toasted.error("Please search school name and select");
        // }
      
    },

    reset() {
      this.clear();
    },

    getAddressData(addressData, placeResultData, id) {
      (this.street = addressData.route || ""),
        (this.state = addressData.administrative_area_level_1 || ""),
        (this.district = addressData.administrative_area_level_2 || ""),
        (this.city = addressData.locality || "");

      if (this.state) {
        this.state = this.stateList[this.state];
      }
      this.address = addressData;
    }
  },

};
</script>

<style lang="scss" scoped>

</style>
