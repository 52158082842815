import axios from "axios";
axios.defaults.timeout = 50000;


async function viGet(url, paramsObj) {
  let res = await axios({
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "get",
    params: paramsObj,
  });
  return res.data;
}

async function viGetOnly(url) {
  let res = await axios({
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "get",
  });
  return res.data;
}

 async function viPutOnly(url,bodyObj,xtoken) {
  let res = await axios({
    headers: {
       Authorization: xtoken,
       branchId: localStorage.getItem('currentBranch'),
       currentAcademicYear: localStorage.getItem('currentAcademicYear'),
      'Content-Type': 'multipart/form-data'
    },
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "put",
    data: bodyObj,
  });
  return res.data;
}

 async function viXGet(url, xtoken) {
  let res = await axios({
    headers: { Authorization: xtoken, branchId: localStorage.getItem('currentBranch'),currentAcademicYear: localStorage.getItem('currentAcademicYear')  },
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "get"
  });
  return res.data;
}

async function viPost(url, bodyObj) {

  let res = await axios({
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "post",
    data: bodyObj,
  });
  return res.data;
}

async function viXPost(url, bodyObj, xtoken) {
  let res = await axios({
    headers: { Authorization: xtoken, branchId: localStorage.getItem('currentBranch'),currentAcademicYear: localStorage.getItem('currentAcademicYear')  },
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "post",
    data: bodyObj,
  });
  return res.data;
}

 async function viXPut(url, bodyObj, xtoken) {
  let res = await axios({
    headers: { Authorization: xtoken, branchId: localStorage.getItem('currentBranch'),currentAcademicYear: localStorage.getItem('currentAcademicYear')  },
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "put",
    data: bodyObj,
  });
  return res.data;
}


 async function viDelete(url,xtoken) {
  let res = await axios({
    headers: { Authorization: xtoken, branchId: localStorage.getItem('currentBranch'),currentAcademicYear: localStorage.getItem('currentAcademicYear')  },
    url: ( process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}/api` : process.env.VUE_APP_VI_SERVICE_PATH ) + url,
    method: "delete",
  });
  return res.data;
}
export default {
  viGet,
  viGetOnly,
  viPutOnly,
  viPost,
  viXGet,
  viXPost,
  viXPut,
  viDelete
};
