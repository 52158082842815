<template>
  <div class="vi-home">
    <!-- <vi-home-hero-search v-if="$route.path == '/' && $route.fullPath != '/#services'" /> -->
    <!-- <vi-home-others v-if="$route.path == '/'" /> -->
    <router-view></router-view>

  </div>
</template>

<script>
import ViHomeHeroSearch from "../components/Home/ViHomeHeroSearch";
import ViHomeOthers from "../components/Home/ViHomeOthers";
import ViSpinner from "../components/Common/ViSpinner.vue";
import secureUI from "../utils/secureUI";
import ViService from "@/services/ViService";
import { setupSocketConnection, socket } from "../configuration/socketio";

import { io } from "socket.io-client";

export default {
  name: "vi-home",
   data() {
    return {
      viLoader: false,
      isShow: false
    };
  },
  // watch: {
  //   searchWords: function(currentVal, oldVal) {
  //     let hasCurrentParam = currentVal || "";
  //     let hasOldParam = oldVal || "";

  //     if (hasCurrentParam != hasOldParam) {
  //       this.getAccountList();
  //     }
  //   },
  // },
  
  created() {

	  this.initMethod();
   // this.setupSocketConnection.setupSocketConnection
    //  setupSocketConnection();
  },
  
  methods: {
    
    initMethod() {
      this.viLoader = true
       setTimeout(() => {
      //  this.viLoader = false
       }, 500);
     // setupSocketConnection()
      let userData = secureUI.sessionGet("user");

      if (userData) {
       
           if(userData.userDetails.profile.name == 'School Admin'){

            if(userData.userDetails.account.version != 'freeVersion'){
              if(userData.subscription.status == 'unpaid' || userData.subscription.status == 'cancelled'){
                this.$router.push("/admission/admissionSettings");
              }             
            }

            

           }

           if(userData.userDetails.profile.name == 'School Admin' || userData.userDetails.profile.name == 'Teacher'){
            let ckeditor = document.createElement('script');    
            ckeditor.setAttribute('src',"https://desk.zoho.in/portal/api/web/inapp/50144000000783001?orgId=60010172851");
            document.head.appendChild(ckeditor);
           }

      
        let userId = userData.userDetails._id;
      

        setupSocketConnection();


          socket.connect();

          socket.auth = {
            userId: userId,
            usetdetails: userData.userDetails,
            sessionId: localStorage.getItem("vidhyaan-session")
          };
         
      }
    }
  },
 
  components: { ViHomeHeroSearch, ViHomeOthers, ViSpinner},
};
</script>

<style lang="scss" scoped></style>
