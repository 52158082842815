<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="min-height:250px"
  >
    <b-spinner :class="textColor" />
    <div v-if="text" :class="textColor" class="m-2">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "vi-spinner",
  props: {
    text: String,
    textColor: String,
  },
};
</script>
