<template>
  <div id="app">
    <!-- <vi-header v-if="$route.path == '/' || $route.path == '/search-schools'"/> -->
    <div class="vi-master"><router-view /></div> 

    <!-- <vi-footer v-if="($route.path == '/' && $route.fullPath != '/#services' && $route.path != '/search-school/overview' && $route.path != '/pricingView') && $route.path != '/search-school/overview' && $route.path != '/pricingView'" /> -->

  </div>
</template>

<script>
import ViFooter from "./views/ViFooter.vue";
import ViHeader from "./views/ViHeader.vue";
export default {
  name: "app",
  components: {
    ViHeader,
    ViFooter,
  },
  created() {
    console.log("1,",this.$route)
  },
};
</script>
<style lang="scss">
*:focus {
  box-shadow: none !important;
  outline: none !important;
}
.dropmenu a.router-link-exact-active.router-link-active {
  background: #ff7f1d !important;
}
.vi-brand-color {
  color: #2954a2 !important;
}
.vi-fs12 {
  font-size: 12px;
}
.vi-primary {
  background: #2954a2 !important;
  border: 0 !important;
  &:hover {
    background: #ff7f1d !important;
  }
}
.web-rounded-xl {
  border-radius: 10rem !important;
}
.vi-h-form-group {
  height: 60px;
  margin-bottom: 2px !important;
}
.vi-error {
  color: #dc3545 !important;
  font-size: 12px !important;
}
.vi-h-input {
  height: 100px;
}
.toasted.viToast.outline {
  color: #dc3545;
  .action,
  .action:hover {
    color: #000;
    text-decoration: none;
  }
}

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid"; // fas
@import "~@fortawesome/fontawesome-free/scss/regular"; // far
@import "~@fortawesome/fontawesome-free/scss/brands"; // fab
</style>
