<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
   <div
     v-if="profile.name == 'School Admin'"
     id="page-container"
     class="sidebar-partial sidebar-no-animations sidebar-visible-lg"
   >
     <div id="sidebar" style="margin-top: 64px;" >
       <!-- <div class="logo">
         <h1>
           <img src="../assets/img/smallLogo.jpg" width="60px" />
           <span>VIDHYAAN</span>
         </h1>
       </div> -->
       <div class="sideprofile">
         <!--<img src="../../assets/img/img_avatar.png" alt="Avatar" class="avatar"> <p>Vimal</p>-->
       </div>
       <nav class="navbar" :style="version == 'paidVersion' ? 'height: 90%;overflow-y: scroll;scrollbar-width: none;' : ''">
         <!-- Links -->
         <ul class="navbar-nav">
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/enrollment/school') }"
             v-if="version == 'freeVersion'"
           >
             <a
               class="nav-link"
               @click.prevent="redirectRoute('/enrollment/school')"
               href="javascript:void(0)"
               ><i 
               :style="isTabSelected('/enrollment/school') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-school"></i>
               <p :style="isTabSelected('/enrollment/school') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Enroll School</p></a
             >
           </li>
<li
             class="nav-item" :style="!isVerified ? 'cursor: not-allowed' : '' "
             :class="{ active: isTabSelected('/admission/online/home') }"
             v-if="version == 'freeVersion'"
           >
             <a
               class="nav-link"
               @click.prevent="redirectRoute('/admission/online/home')"
               href="javascript:void(0)"
               ><i 
               :style="isTabSelected('/admission/online/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-globe"></i>
               <p :style="isTabSelected('/admission/online/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Admission Management</p></a
             >
           </li>
           
           <!-- <li
             class="nav-item"
             :class="{ active: isTabSelected('/admission/admissionOnline') }"
             v-if="version == 'freeVersion'"
           >
             <a
               class="nav-link"
               @click.prevent="redirectRoute('/admission/admissionOnline')"
               href="javascript:void(0)"
               ><i
                 class="fas fa-globe"
                 aria-hidden="true"
                 style="font-size: 1.2rem; margin-top: 3px"
               ></i>
               <p>Online Admission</p></a
             >
           </li> -->
           <li
             class="nav-item" :style="!isVerified ? 'cursor: not-allowed' : '' "
             :class="{ active: isTabSelected('/enrollment/setting') }"
             v-if="version == 'freeVersion'"
           >
             <a v-if="isVerified"
               class="nav-link"
               @click.prevent="redirectRoute('/enrollment/setting')"
               href="javascript:void(0)"
               ><i 
               :style="isTabSelected('/enrollment/setting') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-cog"></i>
               <p :style="isTabSelected('/enrollment/setting') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Settings</p></a
             >
             <a v-else
               class="nav-link"
               :style="!isVerified ? 'cursor: not-allowed' : '' "
               href="javascript:void(0)"
               ><i 
               :style="isTabSelected('/enrollment/setting') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-cog"></i>
               <p :style="isTabSelected('/enrollment/setting') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Settings</p></a
             >
           </li>
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/school/admin/home') }"
             v-if="version == 'paidVersion' && checkHomeTabPermission('check')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/school/admin/home')"
               href="javascript:void(0)"
               >
               <!-- <img src="../assets/img/Home.svg" class="img-fluid" > -->
               <i
                 class="fas fa-home"
                 aria-hidden="true"
                 :style="isTabSelected('/school/admin/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 
               ></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray;padding-left: 5px '  : isTabSelected('/school/admin/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Home</p></a
             >
           </li>
           <li
               class="nav-item"
               v-if="version == 'paidVersion' && checkPermission('Inbox')"
               :class="{ active: isTabSelected('/admission/inbox') }"
             >
               <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
                 class="nav-link"
                 @click.prevent="redirectRoute('/admission/inbox')"
                 href="javascript:void(0)"
                 ><i :style="isTabSelected('/admission/inbox') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-envelope-open-text"></i>
                 <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admission/inbox') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Inbox</p></a
               >
             </li>
           <li 
             class="nav-item"
             :class="{ active: isTabSelected('/admission/home') }"
             v-if="version == 'paidVersion' && checkPermission('School Hub')"
           >
             <a   :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click="redirectRoute('/admission/home')"
               href="javascript:void(0)"
               ><i :style="isTabSelected('/admission/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-school"></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admission/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">School Hub</p></a
             >
           </li>
           <li
               class="nav-item"
               v-if="version == 'paidVersion' && checkPermission('Academic Management')"
               :class="{ active: isTabSelected('/academic/academicManagement') }"
             >
               <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
                 class="nav-link"
                 @click.prevent="redirectRoute('/academic/academicManagement')"
                 href="javascript:void(0)"
                 ><i :style="isTabSelected('/academic/academicManagement') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-book-open"></i>
                 <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/academic/academicManagement') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Academic Management</p></a
               >
             </li>
             <li
               class="nav-item"
               v-if="version == 'paidVersion' && checkPermission('My Schedule')"
               :class="{ active: isTabSelected('/admin/mySchedule/time/period') }"
             >
               <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
                 class="nav-link"
                 @click.prevent="redirectRoute('/admin/mySchedule/time/period')"
                 href="javascript:void(0)"
                 ><i :style="isTabSelected('/admin/mySchedule/time/period') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-clipboard-list"></i>
                 <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admin/mySchedule/time/period') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">My Schedule</p></a
               >
             </li>
             <!-- <li
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/schedule/time/period') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/schedule/time/period')"
                 href="javascript:void(0)"
                 ><i class="fas fa-clipboard-list"></i>
                 <p>My Schedule</p></a
               >
             </li> -->
             <li
               class="nav-item"
               v-if="version == 'paidVersion' && checkPermission('Exam Scheduler')"
               :class="{ active: isTabSelected('/admin/examSchedule') }"
             >
               <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
                 class="nav-link"
                 @click.prevent="redirectRoute('/admin/examSchedule')"
                 href="javascript:void(0)"
                 ><i :style="isTabSelected('/admin/examSchedule') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fa fa-th-large"></i>
                 <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray' : isTabSelected('/admin/examSchedule') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Exam Scheduler</p></a
               >
             </li>
             <li
               class="nav-item"
               v-if="version == 'paidVersion' && checkPermission('Attendance Report')"
               :class="{ active: isTabSelected('/student/attendance/management') }"
             >
               <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
                 class="nav-link"
                 @click.prevent="redirectRoute('/student/attendance/management')"
                 href="javascript:void(0)"
                 ><i :style="isTabSelected('/student/attendance/management') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-chart-line"></i>
                 <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray' : isTabSelected('/student/attendance/management') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Attendance Report</p></a
               >
             </li>
             
            
             <li
             class="nav-item"
             :class="{ active: isTabSelected('/feesManagement/home') }"
             v-if="version == 'paidVersion' && checkPermission('Fee Management')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/feesManagement/home')"
               href="javascript:void(0)"
               ><i :style="isTabSelected('/feesManagement/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-receipt"></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray' : isTabSelected('/feesManagement/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Fees Management</p></a
             >
           </li>
         
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/admission/online/home') }"
             v-if="version == 'paidVersion' && checkPermission('Admission Management')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/admission/online/home')"
               href="javascript:void(0)"
               ><i
                 class="fas fa-globe"
                 aria-hidden="true"
                 :style="isTabSelected('/admission/online/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               ></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admission/online/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'"> Admission Management</p></a
             >
           </li>
            <li 
             class="nav-item"
             :class="{ active: isTabSelected('/admission/alertsAndNotifications') }"
             v-if="version == 'paidVersion' && checkPermission('Alerts & Notification')"
           >
             <a   :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click="redirectRoute('/admission/alertsAndNotifications')"
               href="javascript:void(0)"
               ><i :style="isTabSelected('/admission/alertsAndNotifications') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fa fa-bell" aria-hidden="true"></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admission/alertsAndNotifications') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Alerts & Notifications</p></a
             >
           </li>
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/admission/dashboard') }"
             v-if="version == 'paidVersion' && checkPermission('Dashboard')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link" 
               @click.prevent="redirectRoute('/admission/dashboard')"
               href="javascript:void(0)"
               ><i
                 class="fas fa-chart-pie"
                 aria-hidden="true"
                 :style="isTabSelected('/admission/dashboard') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               ></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admission/dashboard') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Dashboard</p></a
             >
           </li>
           
          
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/admission/ImportExp') }"
             v-if="version == 'paidVersion' && checkPermission('Import/Export')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/admission/ImportExp')"
               href="javascript:void(0)"
               ><i
                 class="fas fa-exchange-alt"
                 aria-hidden="true"
                 :style="isTabSelected('/admission/ImportExp') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                
               ></i>
               <!-- style="transform: rotate(90deg)" -->
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray' : isTabSelected('/admission/ImportExp') ? 'color: #2954a2;font-weight: 800;'  : 'color: white'">Import/Export</p></a
             >
           </li>
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/admin/AcademicRenewal/home') }"
             v-if="version == 'paidVersion' && checkPermission('Promote Students')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/admin/AcademicRenewal/home')"
               href="javascript:void(0)"
             >
               <i  :style="isTabSelected('/admin/AcademicRenewal/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-user-graduate"></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/admin/AcademicRenewal/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Academic Renewal</p></a
             >
           </li>
            
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/vidhyaan/feature/request') }"
             v-if="version == 'paidVersion' && checkPermission('Feature Request')"
           >
             <a :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'cursor: not-allowed;pointer-events: none;color: darkgray' : 'pointer-events: visible;'"
               class="nav-link"
               @click.prevent="redirectRoute('/vidhyaan/feature/request')"
               href="javascript:void(0)"
               ><i  :style="isTabSelected('/vidhyaan/feature/request') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fa fa-th-large"></i>
               <p :style="currentPlanDetails && currentPlanDetails != 'live' && currentPlanDetails != 'dunning' ? 'color: darkgray'  : isTabSelected('/vidhyaan/feature/request') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">FEATURE REQUEST</p></a
             >
           </li>
 
           <!-- <li
               class="nav-item"
               v-if="version == 'paidVersion'"
               :class="{ active: isTabSelected('/admission/inbox') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/admission/inbox')"
                 href="javascript:void(0)"
                 ><i class="fas fa-clipboard-list"></i>
                 <p>Inbox</p></a
               >
             </li> -->
 
           <li
             class="nav-item"
             :class="{ active: isTabSelected('/admission/admissionSettings') }"
             v-if="version == 'paidVersion'"
           >
             <a
               class="nav-link"
               @click.prevent="redirectRoute('/admission/admissionSettings')"
               href="javascript:void(0)"
               ><i  :style="isTabSelected('/admission/admissionSettings') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' " class="fas fa-cog"></i>
               <p :style="isTabSelected('/admission/admissionSettings') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Settings</p></a
             >
           </li>
           
           
         </ul>
       </nav>
     </div>
     <div id="main-container">
       <NavBar />
       <div class="pgwrapper" id="pgwrapper" v-if="isLoadView">
         <router-view></router-view>
       </div>
       <div v-if="!isLoadView">
        <vi-spinner
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="min-height: 600px !important;width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
     />
       </div>
       <div style="margin-left: 24px" v-if="isLoadView">&copy; Vidhyaan.</div>
     </div>
   </div>
 
   <div
     v-else-if="profile.name == 'Parent'"
     id="page-container"
     :class="$route.path == '/parent/search/home' || $route.path == '/parent/search-school' ? 'sidebar-no-animations prntsidebar-visible-lg' : 'sidebar-partial sidebar-no-animations prntsidebar-visible-lg'"
   >
     <div
       v-if="
         profile.name == 'Parent' &&
         $route.path != '/parent/search/home' &&
         $route.path != '/parent/search-school'
       "
       id="page-container"
       class="sidebar-partial sidebar-no-animations prntsidebar-visible-lg"
     >
       <div
         id="sidebar" style="margin-top: 57px;"
         v-if="
           $route.path != '/parent/search/home' &&
           $route.path != '/parent/search-school'
         "
       >
         <!-- <div class="logo">
           <h1>
             <img src="../assets/img/smallLogo.jpg" width="60px" />
             <span @click="goBackVidhyan">VIDHYAAN</span>
           </h1>
         </div> -->
         <div class="sideprofile">
           <!--<img src="../../assets/img/img_avatar.png" alt="Avatar" class="avatar"> <p>Vimal</p>-->
         </div>
         <nav class="navbar">
           <!-- Links -->
           <ul class="navbar-nav">
            
             <li  v-if="!showStudentProfile"
               class="nav-item"
               :class="{ active: isTabSelected('/parent/home') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/parent/home')"
                 href="javascript:void(0)"
                 ><i class="fas fa-home"></i>
                 <p :style="isTabSelected('/parent/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Home</p></a
               >
             </li>
             <li  v-if="!showStudentProfile"
               class="nav-item"
               :class="{ active: isTabSelected('/parent/admission') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/parent/admission')"
                 href="javascript:void(0)"
                 ><i class="fas fa-clipboard-list"></i>
                 <p :style="isTabSelected('/parent/admission') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Admission</p></a
               >
             </li>
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion'"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/home/${studentInformation[0]._id}`) }"
             >
               <a
               @click.prevent="redirectRoute(`/parent/student/home/${studentInformation[0]._id}`)"
              
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/home/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-home"></i>
                 <p :style="isTabSelected(`/parent/student/home/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Home</p></a
               >
             </li>
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion' && checkPermission('Inbox')"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/portal/inbox/details/${studentInformation[0]._id}`) }"
             >
               <a
               @click.prevent="redirectRoute(`/parent/student/portal/inbox/details/${studentInformation[0]._id}`)"
              
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/portal/inbox/details/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-envelope-open-text"></i>
                 <p :style="isTabSelected(`/parent/student/portal/inbox/details/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Inbox</p></a
               >
             </li>
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion'"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/portal/view/details/${studentInformation[0]._id}`) }"
             >
               <a
               @click.prevent="redirectRoute(`/parent/student/portal/view/details/${studentInformation[0]._id}`)"
              
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/portal/view/details/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-user-graduate"></i>
                 <p :style="isTabSelected(`/parent/student/portal/view/details/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Student Profile</p></a
               >
             </li>
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion' && checkPermission('Academic Management') "
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/academicManagement/${studentInformation[0]._id}`) }"
             >
               <a
               @click.prevent="redirectRoute(`/parent/student/academicManagement/${studentInformation[0]._id}`)"
              
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/academicManagement/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-book-open"></i>
                 <p :style="isTabSelected(`/parent/student/academicManagement/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Academic</p></a
               >
             </li>
 
             <!-- <li
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/academicManagement') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/academicManagement')"
                 href="javascript:void(0)"
                 ><i class="fas fa-clipboard-list"></i>
                 <p>Academic Management</p></a
               >
             </li> -->
             
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion' && checkPermission('Fee Management') "
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/feePayment/${studentInformation[0]._id}`) }"
             >
               <a
                 @click.prevent="redirectRoute(`/parent/student/feePayment/${studentInformation[0]._id}`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/feePayment/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-receipt"></i>
                 <p :style="isTabSelected(`/parent/student/feePayment/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Fee Payment</p></a
               >
             </li>
              
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion' && checkPermission('Fee Management') "
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/paymentHistory/${studentInformation[0]._id}`) }"
             >
               <a
                  @click.prevent="redirectRoute(`/parent/student/paymentHistory/${studentInformation[0]._id}`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/paymentHistory/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-file-invoice"></i>
                 <p :style="isTabSelected(`/parent/student/paymentHistory/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Payment History</p></a
               >
             </li>
             <!-- <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion'"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/alerts/${studentInformation[0]._id}`) }"
             >
               <a
               @click.prevent="redirectRoute(`/parent/student/alerts/${studentInformation[0]._id}`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i class="fas fa-bell"></i>
                 <p>Alerts & Notifications</p></a
               >
             </li> -->
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion'"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/student/tc/${studentInformation[0]._id}`) }"
             >
               <a
                 @click.prevent="redirectRoute(`/parent/student/tc/${studentInformation[0]._id}`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/student/tc/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-file-alt"></i>
                 <p :style="isTabSelected(`/parent/student/tc/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">CERTIFICATE REQUEST</p></a
               >
             </li>
              <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion'"
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/ratingAndReview/school/${studentInformation[0]._id}`) }"
             >
               <a
                 @click.prevent="redirectRoute(`/parent/ratingAndReview/school/${studentInformation[0]._id}`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/ratingAndReview/school/${studentInformation[0]._id}`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-edit"></i>
                 <p :style="isTabSelected(`/parent/ratingAndReview/school/${studentInformation[0]._id}`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">REVIEW AND RATINGS </p></a
               >
             </li>
             <li v-if="showStudentProfile && studentInformation.length > 0 && studentInformation[0].school.version == 'paidVersion' && checkPermission('Feature Request') "
               class="nav-item"
               :class="{ active: isTabSelected(`/parent/feature/request`) }"
             >
               <a
                 @click.prevent="redirectRoute(`/parent/feature/request`)"
                 class="nav-link"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected(`/parent/feature/request`) ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fa fa-th-large"></i>
                 <p :style="isTabSelected(`/parent/feature/request`) ? 'color: #2954a2;font-weight: 800;' : 'color: white'">FEATURE REQUEST </p></a
               >
             </li>
           
 
           </ul>
         </nav>
       </div>
     </div>
     <div id="main-container">
       <NavBar />
       <div class="pgwrapper" id="pgwrapper">
         <router-view></router-view>
       </div>
       <div style="margin-left: 24px">&copy; Vidhyaan.</div>
     </div>
   </div>
   <div
     v-else-if="profile.name == 'Teacher'"
     id="page-container"
     class="sidebar-partial sidebar-no-animations sidebar-visible-lg"
   >
     <div
       v-if="profile.name == 'Teacher'"
       id="page-container"
       class="sidebar-partial sidebar-no-animations prntsidebar-visible-lg"
     >
       <div
         id="sidebar"
         style="margin-top: 57px;"
       >
         <!-- <div class="logo">
           <h1>
             <img src="../assets/img/smallLogo.jpg" width="60px" />
             <span @click="goBackVidhyan">VIDHYAAN</span>
           </h1>
         </div> -->
         <div class="sideprofile">
           <!--<img src="../../assets/img/img_avatar.png" alt="Avatar" class="avatar"> <p>Vimal</p>-->
         </div>
         <nav class="navbar">
           <!-- Links -->
           <ul class="navbar-nav">
            
             <li
              
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/home') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/home')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/home') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-home"></i>
                 <p :style="isTabSelected('/teacher/home') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Home</p></a
               >
             </li>
             <li
             v-if="checkPermission('Inbox')"
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/inbox') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/inbox')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/inbox') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-envelope-open-text"></i>
                 <p :style="isTabSelected('/teacher/inbox') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Inbox</p></a
               >
             </li>
              <li
               class="nav-item"
               v-if="checkPermission('My Schedule')"
               :class="{ active: isTabSelected('/teacher/schedule/time/period') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/schedule/time/period')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/schedule/time/period') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-clipboard-list"></i>
                 <p :style="isTabSelected('/teacher/schedule/time/period') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">My Schedule</p></a
               >
             </li>
             <li
             v-if="checkPermission('Academic Management')"
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/academicManagement') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/academicManagement')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/academicManagement') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-book-open"></i>
                 <p :style="isTabSelected('/teacher/academicManagement') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Class Management</p></a
               >
             </li>
             <li
             class="nav-item"
             v-if="checkPermission('Fee Management')"
             :class="{ active: isTabSelected('/feesSummary/invoice/list') }"
            
           >
             <a 
               class="nav-link"
               @click.prevent="redirectRoute('/feesSummary/invoice/list')"
               href="javascript:void(0)"
               ><i 
               :style="isTabSelected('/feesSummary/invoice/list') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-receipt"></i>
               <p :style="isTabSelected('/feesSummary/invoice/list') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Fees Summary</p></a
             >
           </li>
             <li v-if="checkPermission('Attendance Report')"
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/view/student/attendance/management') }"
             >
             <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/view/student/attendance/management')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/view/student/attendance/management') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fas fa-chart-line"></i>
                 <p :style="isTabSelected('/teacher/view/student/attendance/management') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Attendance Report</p></a
               >
             </li>
              <li
              v-if="checkPermission('Exam Scheduler')"
               class="nav-item"
               :class="{ active: isTabSelected('/teacher/examSchedule') }"
             >
               <a
                 class="nav-link"
                 @click.prevent="redirectRoute('/teacher/examSchedule')"
                 href="javascript:void(0)"
                 ><i 
                 :style="isTabSelected('/teacher/examSchedule') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
                 class="fa fa-th-large"></i>
                 <p :style="isTabSelected('/teacher/examSchedule') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Exam Scheduler</p></a
               >
             </li>
             <li
             class="nav-item"
             v-if="checkPermission('Promote Students')"
             :class="{ active: isTabSelected('/teacher/action/promote/students') }"
             
           >
             <a 
               class="nav-link"
               @click.prevent="redirectRoute('/teacher/action/promote/students')"
               href="javascript:void(0)"
             >
               <i 
               :style="isTabSelected('/teacher/action/promote/students') ? 'font-size: 1.2rem;height: 27px;padding-top: 2px;' : 'margin-right: 4px;font-size: 1.2rem;height: 27px;padding-top: 2px;' "
               class="fas fa-user-graduate"></i>
               <p :style="isTabSelected('/teacher/action/promote/students') ? 'color: #2954a2;font-weight: 800;' : 'color: white'">Promote Students</p></a
             >
           </li>
 
           
            
            
           </ul>
         </nav>
       </div>
     </div>
     <div id="main-container">
       <NavBar />
       <div class="pgwrapper" id="pgwrapper">
         <router-view></router-view>
       </div>
       <div style="margin-left: 24px">&copy; Vidhyaan.</div>
     </div>
   </div>
   <div v-else>
     <pricing></pricing>
   </div>
 
    <vi-spinner
                 v-if="viLoader"
                 text="Loading..."
                 textColor="vi-brand-color"
                 class="flex-fill h-100 vi-fs12"
                 style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
      />
   </div>
 </template>
 <script>
 import NavBar from "@/views/ViNavBar.vue";
 import secureUI from "../utils/secureUI";
 import ViService from "@/services/ViService";
 import ViSpinner from "../components/Common/ViSpinner.vue";
 import { eventBus } from ".././event-bus";

 export default {
   name: "vi-admission-home",
 
   data() {
     return {
       isPayment: true,
       version: "",
       isVerified: false,
       viLoader: false,
       isLoadView: true,
       profile: {},
       studentInformation: [],
       showStudentProfile: false,
       localStorageValue: localStorage.getItem("branchSwitch") || true,

     };
   },
   created() {
 
     this.getUserAcountDetails();
   },
   watch: {
    localStorageValue(newValue) {
      console.log('localStorageValue changed to:', newValue);
    },
  },
  mounted() {

    eventBus.$on("branchSwitchLoader", (data) => {
      if(data){
        this.isLoadView = false
      }
    });
   
    
  },
   computed: {
       styles(){
        var brwHeight = window.innerHeight;
        return{
          height: (brwHeight - 90) + 'px'
        };
       },
     
        currentPlanDetails() {
         const details = this.$store.getters.currentPlanDetail;
         if(details){
              return details.status
         }else{
           return 'live'
         }
     },
    //  isLoadView(){
    //   return localStorage.getItem("branchSwitch");
    //   // return selectedBranch

    //  },
   },
   methods: {
     isTabSelected(currentPath) {
         let data = localStorage.getItem('activeTab');
         if(data){
                return data.includes(currentPath);
         }else{
           return false
         }
 
     },
    

     checkPermission(data){
         const details = this.$store.getters.getTabPermission;
         if(details && details.length > 0){
           if(details.includes(data)){
             return true
           }else{
             return false
           }
         }else{
           return false
         }
       },
       checkHomeTabPermission(data){
         const details = this.$store.getters.getTabPermission;
         if(details && details.length > 0){
           // if(details.includes(data)){
             return true
           // }else{
           //   return false
           // }
         }else{
           return false
         }
       },
     // setLocalActiveTab(path){
     //   localStorage.setItem("previousTab",path);
     //   localStorage.setItem("activeTab",path);
     // },
     redirectRoute(vipath) {
       let currentRoute = ''
       if(this.$route.path == '/admission/home' || this.$route.path == '/admission/academicYear' || this.$route.path == '/admission/teacher' || this.$route.path == '/admission/department'  || this.$route.path == '/admission/class' || this.$route.path == '/admission/student' || this.$route.path == '/admission/new' || this.$route.path == '/admission/term/setting'){
         currentRoute = '/admission/home'
       }else if(this.$route.path == '/feesManagement/home' || this.$route.path == '/feesManagement/createFeeType' || this.$route.path == '/feesManagement/createPlan' || this.$route.path == '/feesManagement/adhocfees' || this.$route.path == '/feesManagement/paymentGateWay' || this.$route.path == '/feesManagement/invoice/feePlan'){
         currentRoute = '/feesManagement/home'
       }else{
         currentRoute = this.$route.path
       }
       localStorage.setItem("previousTab",currentRoute);
       localStorage.setItem("activeTab",vipath);
       if (this.$route.path !== vipath) this.$router.push({ path: vipath });
 
     },
     goBackVidhyan() {
       if(!this.showStudentProfile){
          this.$router.push("/parent/search/home");
       }
       
     },
     getUserAcountDetails() {
       
       let userData = secureUI.sessionGet("user");
 
       if (!userData) {
         this.$toasted.error("Please login and do the action");
         this.$router.push("/login");
       } else {
         this.profile = userData.userDetails.profile;
         this.showStudentProfile = userData.userDetails.isShowStudentProfile
 
         if (userData.userDetails.profile.name == "School Admin") {
           this.isPayment = userData.userDetails.account.isPayment;
           this.version = userData.userDetails.account.version;
          this.isVerified = userData.userDetails.account.isVerified || false
 
           if(userData.userDetails.profile == 'School Admin' && this.version != 'freeVersion'){
             if(userData.subscription.status != 'live'){
 
               this.$router.push("/admission/admissionSettings");
             }
           }
 
 
           if(this.profile.name == 'Parent'){
            this.getStudentAndSchoolInfo()
           }
         }else{
            if(this.profile.name == 'Parent'){
            this.getStudentAndSchoolInfo()
           }
           
         }
       }
     },
     getStudentAndSchoolInfo() {
       let userData = secureUI.sessionGet("user");
 
       ViService.viXGet(
         "/contact/getStudentAndSchoolDetails/parent/portal",
         userData.token
       )
         .then((res) => {
           if (res.isSuccess) {
             let data = secureUI.secureGet(res.data);
             if (res.data) {
               this.studentInformation = data;
 
               if(this.studentInformation && this.studentInformation.length > 0 && this.studentInformation[0].school && this.studentInformation[0].school.tabPermission){
                 let getTabpermissionNameOnly = this.studentInformation[0].school.tabPermission.map( x => x.name)
                 
                 this.$store.dispatch("setTabPermission",getTabpermissionNameOnly);
               }
             }
           } else {
             this.$toasted.show(res.message);
           }
         })
         .catch((e) => {
           let eData = errorLog.apiErrorLog(e);
           this.$toasted.error(eData);
         });
     },
     navigateToSearch() {
       let requestBody = {
         schoolName: null,
         category: null,
         city: null,
       };
 
       ViService.viPost("/app/school/parentPortal", requestBody)
         .then((res) => {
           if (res.isSuccess) {
             let data = secureUI.secureGet(res.data);
             if (data && data.length > 0) {
               this.$store.dispatch("setSchoolList", data);
               this.$router.push({
                 name: "schools",
               });
               this.$router.push("/parent/search-school");
             } else {
               this.$toasted.error("Records not found");
             }
           } else {
             this.$toasted.error(res.message);
           }
         })
         .catch((e) => {
           let eData = errorLog.apiErrorLog(e);
           this.$toasted.error(eData);
         });
     },
   },
   components: {
     NavBar,
     ViSpinner,
     pricing: () => import("./ViPricing.vue"),
   },
 };
 </script>
 
 <style lang="scss" scoped></style>