import secureUI from "@/utils/secureUI";

export default (to, from, next) => {
  // check the local storage exist in the file
  if (
    !localStorage.getItem("user") 
  ) {
    next({ path: "/login" });
  } else {

    let userData = secureUI.sessionGet("user");
    if(to.meta.history && userData.userDetails.profile.name == to.meta.history){
      next();
    }else{
      if(userData.userDetails.profile.name == 'School Admin'){

        if(userData.userDetails.account.version == 'freeVersion'){
           localStorage.setItem("previousTab", "/enrollment/school");
           localStorage.setItem("activeTab",'/enrollment/school');
           next({ path: "/enrollment/school" });
        }else{
           localStorage.setItem("previousTab", "/admission/home");
           localStorage.setItem("activeTab",'/admission/home');
           next({ path: "/admission/home" });
           
        }
       }else if(userData.userDetails.profile.name == 'Teacher'){
        localStorage.setItem("previousTab", "/teacher/home");
        localStorage.setItem("activeTab",'/teacher/home');
        next({ path: "/teacher/home" });
       }
       else{
        localStorage.setItem("previousTab", "/parent/search/home");
        localStorage.setItem("activeTab",'/parent/search/home');
        next({ path: "/parent/search/home" });
       }
    } 
  }
};
