// document.addEventListener("contextmenu", (event) => event.preventDefault());
//function to remove hover effects if button is disabled
window.onload = function(){
  var btnElement = document.getElementById('btnSt');
  
  if (btnElement.disabled) {
    btnElement.classList.add("btndisabled");
  }
};

window.onscroll = function() {
  scrollFunction();
};
/*var body = document.body,
    html = document.documentElement;

var browserhgt = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );

var pghgt = browserhgt - 100; alert(pghgt);*/

//document.getElementById("pgwrapper").style.minHeight = pghgt + "px";
//Vue.set(this.Pgwrp, 'height', pghgt);

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("headerweb").style.height = "56px";
    document.getElementById("headerweb").classList.add("fixed-top");
  } else {
    document.getElementById("headerweb").style.height = "65px";
    document.getElementById("headerweb").classList.remove("fixed-top");
  }
  let filtsect = document.getElementById("filtersect");
  
  

  if (filtsect) {
    if (
      document.body.scrollTop > 70 ||
      document.documentElement.scrollTop > 70
    ) {
      filtsect.classList.add("fixed-top");
      filtsect.style.top = "56px";
      filtsect.style.zIndex = "12";
    } else {
      filtsect.classList.remove("fixed-top");
    }
  }

  let lftFilter = document.getElementById("lftFilter");
  let schlist = document.getElementById("schlist");
  let fromTop = window.scrollY;
  let sticky = lftFilter.offsetTop;

 /* if (window.pageYOffset > sticky) {
    lftFilter.classList.add("sticky");
    lftFilter.style.top="70px";
    lftFilter.style.width="270px";
  } else {
    lftFilter.classList.remove("sticky");
  }*/

  if (
    schlist.offsetTop <= fromTop &&
    schlist.offsetTop + schlist.offsetHeight > fromTop
  ) {
    lftFilter.classList.add("sticky");
    lftFilter.style.top="70px";
    lftFilter.style.width="240px";
  } else {
    lftFilter.classList.remove("sticky");
  }
}
/* search Modal box */
function openSearch() {
  document.getElementById("myOverlay").style.display = "block";
  /*  document.body.style.overflow = "hidden";*/
}

/* modal box */
const openEls = document.querySelectorAll("[data-open]");
const closeEls = document.querySelectorAll("[data-close]");
const isVisible = "is-visible";

for (const el of openEls) {
  el.addEventListener("click", function() {
    const modalId = this.dataset.open;
    document.getElementById(modalId).classList.add(isVisible);
  });
}

for (const el of closeEls) {
  el.addEventListener("click", function() {
    this.parentElement.parentElement.parentElement.classList.remove(isVisible);
  });
}

document.addEventListener("click", (e) => {
  if (e.target == document.querySelector(".modal.is-visible")) {
    document.querySelector(".modal.is-visible").classList.remove(isVisible);
  }
});

document.addEventListener("keyup", (e) => {
  // if we press the ESC
  if (e.key == "Escape" && document.querySelector(".modal.is-visible")) {
    document.querySelector(".modal.is-visible").classList.remove(isVisible);
  }
});

// /* tab section*/
var tabLinks = document.querySelectorAll(".web-tablinks");
var tabContent = document.querySelectorAll(".web-tabcontent");

tabLinks.forEach(function(el) {
  el.addEventListener("click", openTabs);
});

function openTabs(el) {
  var btnTarget = el.currentTarget;
  var search = btnTarget.dataset.search;

  web-tabcontent.forEach(function(el) {
    el.classList.remove("active");
  });

  web-tablinks.forEach(function(el) {
    el.classList.remove("active");
  });
  document.querySelector("#" + search).classList.add("active");
  btnTarget.classList.add("active");
}

/* home search section */

function tabactive(e) {
  var elems = document.querySelectorAll(".tabactive");
  [].forEach.call(elems, function(el) {
    el.classList.remove("tabactive");
  });
  e.target.className = "tabactive";
}

function showregopt() {
  document.getElementById("web-regopt").classList.remove("web-regopt");
}

/*
(function() {
    // Add event listener
    document.addEventListener("mousemove", parallax);
    const elem = document.querySelector("#parallax");
    // Magic happens here
    function parallax(e) {
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        let _mouseX = e.clientX;
        let _mouseY = e.clientY;
        let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
        let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
        let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
        let x = `${_depth3}, ${_depth2}, ${_depth1}`;
        elem.style.backgroundPosition = x;
    }

})();*/

function OnOffAdm(RadioChecked) {
	if(document.getElementById("admon").checked){
		onlineadm.style.display='block';
		offlineadm.style.display='none';
	}else
		if(document.getElementById("admoff").checked){
		offlineadm.style.display='block';
		onlineadm.style.display='none';
	}
}

function preview_image(event){
	var reader = new FileReader();
	reader.onload = function()
	{
		var output = document.getElementById('output_image');
		output.src = reader.result;
	}
	reader.readAsDataURL(event.target.files[0]);
}

function overviewpost(){
   var overview = document.getElementById("overview").offsetTop;
   window.scrollTo(0,overview);
}
function seatavailpost(){
   var seatavail = document.getElementById("seatavailability").offsetTop;
   window.scrollTo(0,seatavail);
}
function facilitiespost(){
	var facil = document.getElementById("facilities").offsetTop ;alert("facil "+facil);
   window.scrollTo(0,facil);
}
function feestrucpost(){
	var fee = document.getElementById("feestruc").offsetTop;
   window.scrollTo(0,fee);
}
function contactdetpost(){
	var contdet = document.getElementById("contactdet").offsetTop;
   window.scrollTo(0,contdet);
}
function ratingpost(){
	var rating = document.getElementById("rating").offsetTop;alert("rating "+rating);
   window.scrollTo(0,rating);
}


