import "babel-polyfill";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import ViService from "@/services/ViService";
import store from "./store";
import VueCryptojs from "vue-cryptojs";
import {
  BootstrapVue,
  BootstrapVueIcons,
  PaginationPlugin,
} from "bootstrap-vue";
import Icon from "vue-awesome/components/Icon";
import Toasted from "vue-toasted";
import VueMeta from "vue-meta";
import VueApexCharts from 'vue-apexcharts'

import { dateFilter, dateFormatFilter, dateAndTimeFormatFilter, getFormattedDateTime, currencyDecimalFilter } from './configuration/filter'

import scrollSpy, { Easing } from "vue2-scrollspy";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "../src/assets/css/v-select.css";
import "../src/assets/css/style.css";
import "../src/assets/css/animate.css";
import "../src/assets/css/loginStyles.css";
import "../src/assets/js/animatewow.js";
import "../src/assets/js/main.js";
import "../src/assets/css/adminstyles.css";
import "../src/assets/css/restable.css";
import "../src/assets/css/vidhui_style.css"
// import "../src/assets/css/figmaStyles.css"

import secureUI from "@/utils/secureUI";
import * as VeeValidate from 'vee-validate';
import * as VueGoogleMaps from "vue2-google-maps";
import TurnOffAutocomplete from 'vue-turn-off-autocomplete';

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA0VSaHmsUM8If8jEipJAYJ66js87WD4Co",
  },
  installComponents: true,
});

Vue.config.productionTip = false;
Vue.component("v-icon", Icon);
Vue.use(TurnOffAutocomplete);
Vue.use(VueCryptojs);
Vue.use(VeeValidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(PaginationPlugin);
Vue.use(VueApexCharts);
Vue.use(scrollSpy, {
  easing: Easing.Cubic.In,
});

Vue.filter('date', dateFilter);
Vue.filter('dateFormat', dateFormatFilter);
Vue.filter('currencyFormat', currencyDecimalFilter);
Vue.filter('dateTimeFormat', getFormattedDateTime);
Vue.filter('dateAndTimeFormat', dateAndTimeFormatFilter);


let toastOptions = {
  theme: "outline",
  position: "top-center",
  duration: 2000,
  action: [
    {
      text: "Ok",
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  ],
  className: "viToast shadow",
};
Vue.use(Toasted, toastOptions);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.component("v-icon", Icon);
Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    this.initialMethod()
  },
  methods: {
    async initialMethod(){
      try {
      
      var url = window.location.href;

      var token = new URL(url).searchParams.get("token");

      
      if( token ) {
        localStorage.removeItem('user');
        let check = decodeURIComponent( token )
        localStorage.setItem("user", decodeURIComponent( token ) );
      }

      let userData = secureUI.sessionGet("user");
      if (userData) {
        if( token ) {
          if(userData.userDetails.branch && userData.userDetails.branch._id){
            localStorage.setItem("currentBranch", userData.userDetails.branch._id);
          }
          if(userData.userDetails.account && userData.userDetails.account.currentAcademicYear._id){
            this.getSchoolAcdyrCurrent(userData.userDetails.account._id)

          }


     
        }
      
        const response = await ViService.viXGet("/auth/authenticate",userData.token);
        
        if (response.success) {
          const validateResponse = await ViService.viXGet(`/auth/validate/authenticate?email=${response.data.user.userName}`,userData.token);

          if (validateResponse.isSuccess) {
            
            localStorage.setItem("messageAction",validateResponse.data.userDetails.isEnterBtnEnable);


            if(userData.userDetails.profile.name == 'School Admin'){
              localStorage.setItem("branchSwitch", true);

              if(userData.userDetails.account.subScriptionStatus == 'Enroll' && validateResponse.data.userDetails.account.subScriptionStatus && validateResponse.data.userDetails.account.subScriptionStatus == 'Paid'){
                this.$store.dispatch("setFetchAdmissionObjCount", false);
                this.$store.dispatch("setFetchFeesObjCount", false);
                this.$store.dispatch("setFetchEnrollObjCount", false);
                localStorage.removeItem("user");
                localStorage.removeItem("vidhyaan-session");

                localStorage.removeItem("currentBranch");
                localStorage.removeItem('currentAcademicYear');

                localStorage.removeItem('messageAction');
                window.location.href = process.env.VUE_APP_REDIRECT_URL;
              }
            }
            
                  const pathName = window.location.pathname;
                  this.$store.dispatch('setUserDetail', validateResponse.data.userDetails);
                  
                  if(userData.userDetails.profile.name == 'School Admin'){
                    if(validateResponse.data && validateResponse.data.subscription && validateResponse.data.subscription.length > 0){
                      let data = validateResponse.data.subscription.filter( x => x.status == 'live')
                      this.$store.dispatch("setCurrentPlanDetail",data);

                    }
                  }
                  
                  if (pathName === '/' || pathName === '/home') {

                      if(userData.userDetails.profile.name == 'School Admin'){
                                             
                        if(userData.userDetails.account.version == 'freeVersion'){
                          localStorage.setItem("activeTab",'/enrollment/school');
                          this.$router.push('/enrollment/school');
                        }else{
                          let allownavigate = false
                          if(validateResponse.data.subscription && validateResponse.data.subscription.length > 0){
                           let data = validateResponse.data.subscription.filter( x => x.status == 'live')
                           if(data.length == 0){
                            allownavigate = true
                           }
                          }

                         // if(validateResponse.data.subscription.status == 'dunning' || validateResponse.data.subscription.status == 'unpaid' || validateResponse.data.subscription.status == 'cancelled'){
                          if(allownavigate){
                            localStorage.setItem("activeTab", "/admission/admissionSettings");
                            this.$router.push("/admission/admissionSettings");
                          }else{
                            if(validateResponse.data.userDetails.account.student && validateResponse.data.userDetails.account.student.length > 0){
                              localStorage.setItem("activeTab", "/school/admin/home");
                              this.$router.push("/school/admin/home");
                            }else{
                              localStorage.setItem("activeTab", "/admission/home");
                              this.$router.push("/admission/home");
                            }
                             
                          }
                                                
                        }
                        
                      } else if (userData.userDetails.profile.name == "Vidhyaan Admin") {
                        localStorage.setItem("activeTab", "/admin-panel");
                        localStorage.setItem("superAdminuser", decodeURIComponent( token ) );
                        this.$router.push("/admin-panel");
                      } else if ( userData.userDetails.profile.name == "Teacher") {
                       // const jsonString = JSON.stringify(userData.userDetails.account.currentAcademicYear);
                        this.getSchoolAcdyrCurrent(userData.userDetails.account._id)
      
                        localStorage.setItem("activeTab", "/teacher/home");
                        localStorage.setItem("previousTab", "/teacher/home");
                        this.$router.push("/teacher/home");
                      }
                      else {
                        if(userData.userDetails.isShowStudentProfile){
                          this.getStudentAndSchoolInfo()
                          
                        }else{
                          localStorage.setItem("activeTab", "/parent/search/home");
                          this.$router.push("/parent/search/home");
                        }
                      }
                  }

                  if(userData.userDetails.profile.name == 'School Admin' || userData.userDetails.profile.name == 'Teacher'){
                    
                    if(validateResponse.data && validateResponse.data.userDetails && validateResponse.data.userDetails.account && validateResponse.data.userDetails.account.tabPermission){
                      let getTabpermissionNameOnly = validateResponse.data.userDetails.account.tabPermission.map( x => x.name)
                      this.$store.dispatch("setTabPermission",getTabpermissionNameOnly);
                    }
                    
                   // 

                    this.getSchoolAcdyrCurrent(userData.userDetails.account._id)

                  }
                  if(userData.userDetails.profile.name == 'School Admin'){
                    let getQuantity = validateResponse.data.userDetails.account.subscriptionQuantity || 1

                    this.$store.dispatch("setSubscriptionQuantity",getQuantity);

                  }
                  
                 


          }else {
            localStorage.removeItem('user');
            localStorage.removeItem('messageAction');
            window.location.href = process.env.VUE_APP_REDIRECT_URL + '/login';
        }
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('messageAction');
            window.location.href = process.env.VUE_APP_REDIRECT_URL + '/login';
        }
      } else {
        const pathName = window.location.pathname;
        if(pathName != '/change-password' && pathName != '/admin-panel' && pathName != '/invitaion-accept'  && pathName != '/search-school/overview' && this.$route.fullPath != '/#whyvidhyaan' && pathName != '/pricingView'){
          window.location.href = process.env.VUE_APP_REDIRECT_URL;
        }
          
      }
    } catch (err) {

      this.$store.dispatch("setFetchAdmissionObjCount", false);
      this.$store.dispatch("setFetchFeesObjCount", false);
      this.$store.dispatch("setFetchEnrollObjCount", false);

      localStorage.removeItem("user");
      localStorage.removeItem("vidhyaan-session");
      localStorage.removeItem('currentAcademicYear');

      localStorage.removeItem("currentBranch");
      localStorage.removeItem('messageAction');
      window.location.href = process.env.VUE_APP_REDIRECT_URL;

      console.log("in catch error ==> ", err);
    }
    },
    getStudentAndSchoolInfo() {
      let userData = secureUI.sessionGet("user");

      ViService.viXGet(
        "/contact/getStudentAndSchoolDetails/parent/portal",
        userData.token
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {

              const studentInformationDetails = data;
              const studentProfiles = studentInformationDetails.filter(x => x.type == 'Contact')
              this.studentInformation = studentProfiles[0]
              
              localStorage.setItem("activeTab",`/parent/student/home/${this.studentInformation._id}`);

              this.$router.push(`/parent/student/home/${this.studentInformation._id}`);
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    getSchoolAcdyrCurrent(id) {
      let userData = secureUI.sessionGet("user");
     
      let selectedBranch = localStorage.getItem("currentBranch");
     
      ViService.viXGet(`/account/getAccountAcademicYearConfigDetails?accountId=${id}&branchId=${selectedBranch}`,userData.token).then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              localStorage.setItem("currentAcademicYear",data.academicYear._id);
              this.$store.dispatch('setCurrentAcademicYear', data.academicYear);
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },

  }
}).$mount("#app");
