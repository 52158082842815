<template>
  <div class="admheader">
   
    <div class="row" style="align-items: center; margin-left: 0px">
     
      <div class="col-3 col-sm-4">
        <img
          src="../assets/img/vidhyaanLogoNew.png"
          width="50px"
          style="border-radius: 29px"
        /> 
        <span >

          <h1
            class="schname"
            style="
              width: auto !important;
              margin-left: 63px;
              /* margin-left: 0% !important; */
              margin-top: 3% !important;
              font-size: 1.4rem;
              position: absolute; top: 1px
             
            "
            v-if="schoolItem.accountName"
          >
            {{ schoolItem.accountName
            }}<span v-if="schoolItem.address">,{{ schoolItem.address }}</span>
          </h1>
          <h1
            class="schname"
            style="
             top: 0px;
              right: 149px;
              width: auto !important;
              margin-left: 0% !important;
              overflow: visible;
              position: absolute;
            "
            v-if="userDetail.profile.name == 'Parent'"
          >
            <!-- Student Portal -->
            <span style="color: #2954a2;font-family: 'Proxima Nova SemiBold';font-size: var(--bold);font-weight: 600;"
              v-if="
                studentInformation &&
                studentInformation.school &&
                studentInformation.school.accountName
              "
              >{{ studentInformation.school.accountName }}</span
            >
          </h1>
          <h1
            class="schname"
            style="
              top: 0px;
              right: 149px;
              width: auto !important;
              margin-left: 0% !important;
              
              overflow: visible;
              position: absolute;
            "
            v-if="userDetail.profile.name == 'Teacher'"
          >
            <span style="color: #2954a2;font-family: 'Proxima Nova SemiBold';font-size: var(--bold);font-weight: 600;"
              v-if="
                userDetail &&
                userDetail.account &&
                userDetail.account.accountName
              "
              >{{ userDetail.account.accountName }}</span
            >
          </h1>
          </span
        >
      </div>

      <div class="col-6 col-sm-5">
        <div class="row" style="margin-left: 30px; margin-top: 8px">
          <div class="col-6">
            <span
              class="has-float-label"
              v-if="userDetail.profile.name != 'Parent'"
            >
              <input
                type="text"
                disabled
                :value="currentAcademicYear"
                placeholder="Current Academic Year"
                class="form-control form-input"
              />

              <label for="stdFirstName">Academic Year</label>
            </span>
            <span
              class="has-float-label"
              v-if="userDetail.profile.name == 'Parent'"
            >
              
              <label for="stdFirstName"  style="color: rgb(41, 84, 162);font-family: 'Proxima Nova SemiBold';font-size: var(--bold);font-weight: 600;">Student Hub</label>
            </span>
          </div>
          <div class="col-6">
            <span
              class="has-float-label"
              v-if="userDetail.profile.name != 'Parent'"
            >
              <select
                class="form-control form-input"
                id="deptlistid"
                @change="switchBranch(selectedBranch)"
                v-model="selectedBranch"
              >
                <option
                  v-for="(item, index) in allBranchList"
                  :key="index"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
              <label for="clslistid">Branch </label>
            </span>
          </div>
        </div>
      </div>

      <div class="profile col-3 col-sm-3" style="width: 23%">
        <div class="top-menu">
          <ul class="nav navbar-nav pull-right">
            <li
              v-if="
                !userDetail.isShowStudentProfile &&
                userDetail.profile.name == 'Parent'
              "
            >
              <div>
                <label class="switchtop" style="margin-bottom: 0rem"
                  ><input
                    v-model="parentButtonToggle"
                    @input="setRoutes"
                    type="checkbox"
                    id="sibtogBtn" />
                  <div class="slidertop"></div
                ></label>
              </div>
            </li>

            <li
              class="smenu"
              v-if="
                userDetail.profile.name != 'Parent' &&
                userDetail.profile.name != 'Teacher'
              "
            >
              <!-- <i class="fas fa-arrow-circle-up"></i> -->
              <a href="javascript:void(0);" @click="goToPlanDetails"
                ><img style="height: 30px" src="../assets/img/setting.png"
              /></a>
              <!-- <img src="assets/img/staff_img.png" alt=""> -->
              <!-- <a href="javascript:void(0);" @click="goToPlanDetails">Account</a> -->
            </li>
            <li class="addropdown">
              <span
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                <img alt="" class="avatar" src="../assets/img/img_avatar.png" />
                <span
                  class="username username-hide-on-mobile"
                  v-if="
                    adminUser &&
                    adminUser.firstName &&
                    userDetail.profile.name != 'Parent'
                  "
                >
                  {{
                    adminUser
                      ? adminUser.firstName + " " + adminUser.lastName
                      : ""
                  }}</span
                >
                <span
                  class="username username-hide-on-mobile"
                  v-if="
                    userDetail.profile.name == 'Parent' &&
                    studentInformation &&
                    studentInformation.firstName
                  "
                >
                  {{
                    studentInformation
                      ? studentInformation.firstName +
                        " " +
                        studentInformation.lastName
                      : ""
                  }}</span
                >
                <i class="fa fa-angle-down"></i>
              </span>
              <div class="addropdown-content wow bounceIn">
                <div class="arrow-up"></div>
                <div class="addropmenu">
                  <span class="usernamemob" v-if="adminUser">
                    {{
                      adminUser ? adminUser.firstName + adminUser.lastName : ""
                    }}</span
                  >
                  <a
                    style="cursor: default"
                    v-if="
                      userDetail.profile.name != 'Parent' &&
                      userDetail.profile.name != 'Teacher'
                    "
                    >School ID - {{ schollNameAndIdPrefix }}</a
                  >
                  <a @click.prevent="openProfilePopUp" href="javascript:void(0)"
                    ><i class="fa fa-user pr-2" aria-hidden="true"></i
                    >Profile</a
                  >
                  <a href="#" class="mobview">
                    <i class="fas fa-rupee-sign certificateRs pr-2"></i>Upgrade
                    Your Premium
                  </a>
                  <a
                    class="mobview"
                    v-if="
                      userDetail.profile.name == 'Parent' &&
                      $route.path == '/parent/search/home'
                    "
                    @click="goToParentPortal"
                    ><i class="fas fa-sign-out-alt pr-2"></i>Access to Parent
                    Portal</a
                  >
                  <a
                    class="mobview"
                    v-if="
                      userDetail.profile.name == 'Parent' &&
                      $route.path == '/parent/home'
                    "
                    @click="goToParentPortalSearch"
                    ><i class="fas fa-search"></i>Access to Parent School
                    Search</a
                  >

                  <a href="javascript:void(0)" @click.prevent="logout"
                    ><i class="fas fa-sign-out-alt pr-2"></i>Logout</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <b-modal id="profilePopUp" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close" @click.prevent="closeUserPopUp">
        ×
      </button>
      <div class="contact-form">
        <h2>User Profile</h2>
        <br />
        <div>
          <form
            data-vv-scope="userValidate"
            id="addteacher"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div
                class="form-group input-group col-md-6 mt-3 pr-md-3"
                v-if="userDetail.profile.name != 'Parent'"
              >
                <span class="has-float-label">
                  <input
                    type="text"
                    name="First Name"
                    class="form-control form-input"
                    id="tchrnameid"
                    v-model="user.firstName"
                    placeholder="First Name"
                    v-validate="'required'"
                  />
                  <label
                    v-if="errors.first('userValidate.First Name')"
                    class="vi-error"
                    for="emailid"
                    style="top: -1em"
                    >First Name is required</label
                  >
                  <label for="tchrnameid" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 mt-3 pr-md-3"
                v-if="userDetail.profile.name != 'Parent'"
              >
                <span class="has-float-label">
                  <input
                    type="text"
                    name="lastName"
                    class="form-control form-input"
                    id="lastName"
                    v-model="user.lastName"
                    placeholder="Last Name"
                    v-validate="'required'"
                  />
                  <label
                    v-if="errors.first('userValidate.lastName')"
                    class="vi-error"
                    for="emailid"
                    style="top: -1em"
                    >Last Name is required</label
                  >
                  <label for="lastName" v-else
                    >Last Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 mt-3 pr-md-3"
                v-if="userDetail.profile.name == 'Parent'"
              >
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="First Name"
                    class="form-control form-input"
                    id="tchrnameid"
                    v-model="studentInformation.firstName"
                    placeholder="First Name"
                    v-validate="'required'"
                  />

                  <label for="tchrnameid"
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 mt-3 pr-md-3"
                v-if="userDetail.profile.name == 'Parent'"
              >
                <span class="has-float-label">
                  <input
                    type="text"
                    name="lastName"
                    disabled
                    class="form-control form-input"
                    id="lastName"
                    v-model="studentInformation.lastName"
                    placeholder="Last Name"
                    v-validate="'required'"
                  />

                  <label for="lastName"
                    >Last Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3">
                <span class="web-has-float-label">
                  <input
                    type="text"
                    v-model="user.role"
                    class="form-control form-input"
                    id="stateid"
                    disabled
                    name="roleId"
                    placeholder="Role"
                  />

                  <label for="lastName"
                    >Role <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required|numeric|min:10|max:10'"
                    name="mbnoUser"
                    maxlength="10"
                    v-model="user.phoneNo"
                    class="form-control form-input"
                    id="mbnoUser"
                    placeholder="Mobile"
                  />

                  <label
                    v-if="errors.first('userValidate.mbnoUser')"
                    class="vi-error"
                    style="top: -1em"
                  >
                    Mobile is required</label
                  >
                  <label v-else>Mobile <span class="required">*</span></label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required|email'"
                    name="email"
                    disabled
                    v-model="user.email"
                    class="form-control form-input"
                    id="email"
                    placeholder="Email Id"
                  />
                  <a
                    href="javascript:void(0);"
                    style="color: red"
                    @click="resetMailId"
                    >Change Primary Email</a
                  >

                  <label
                    v-if="
                      errors.first('userValidate.email') ||
                      !isUserEmailIdAvailable
                    "
                    class="vi-error"
                    style="top: -1em"
                  >
                    {{
                      errors.first("userValidate.email")
                        ? "Email Id is required and (eg: abc@gmail.com)"
                        : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                    }}</label
                  >
                  <label v-else>Email Id <span class="required">*</span></label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="userName"
                    class="form-control form-input"
                    id="userName"
                    disabled
                    v-model="user.userName"
                    placeholder="User Name"
                  />

                  <label for="userName"
                    >User Name <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateUser"
                >
                  <span>Update</span>
                </button>

                <button
                  type="button"
                  @click.prevent="closeUserPopUp"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal
      id="resetMailPopup"
      style="width: 40%"
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
    >
      <button
        type="button"
        class="close"
        @click.prevent="closePrimiaryMailChange"
      >
        ×
      </button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form" v-if="isVerifyCode == false">
        <!-- <span class="close">&times;</span> -->
        <h2>Change Primary Mail</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="web-has-float-label">
              <input
                @input="checkNewPrimaryEmail"
                class="form-control form-input"
                v-model="newPrimaryEmail"
              />
              <label
                v-if="invalidNewMail"
                class="vi-error"
                for="emailid"
                style="top: -1em"
                >Please enter valid mail format</label
              >

              <label for="passwordid" v-else
                >New Primary Mail<span class="required">*</span></label
              >
            </span>
          </div>
        </div>
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            :disabled="!newPrimaryEmail"
            type="button"
            @click.prevent="primaryMailVerify"
            class="btn btnsml"
            id="svbtn"
          >
            <span>Confirm</span>
          </button>
          <button
            type="button"
            class="btn btncl clsmdl"
            @click.prevent="closePrimiaryMailChange"
            id="clbtn"
          >
            <span>Cancel</span>
          </button>
        </div>
      </div>
      <div class="contact-form" v-else>
        <h2>Enter 4 digit code sent to your registered email id</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="web-has-float-label">
              <input
                type="number"
                onKeyPress="if(this.value.length==4) return false;"
                name="verifyCode"
                class="form-control web-form-input"
                id="verifyCodeid"
                v-model="userVerifyCode"
                min="4"
              />

              <label for="verifyCodeid"
                >4-digit code <span class="required">*</span></label
              >
            </span>
          </div>
        </div>
        <div
          class="pt-3 text-enter dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            :disabled="!userVerifyCode"
            type="button"
            @click.prevent="verifyPrimaryMailCode"
            class="btn btnsml"
            id="svbtn"
          >
            <span>Verify</span>
          </button>
          <button
            @click.prevent="closePrimiaryMailChange"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import secureUI from "../utils/secureUI";
import ViService from "@/services/ViService";
import { eventBus } from ".././event-bus";

export default {
  name: "vi-nav-bar",

  data() {
    return {
      userDetail: {},
      schoolDetails: {},
      cloneUserDetails: {},
      //schollNameAndIdPrefix: '',
      user: {
        _id: null,
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: null,
        role: null,
        isActive: true,
        userName: "",
      },
      isUserEmailIdAvailable: true,
      isMobileAvailable: true,
      parentButtonToggle: true,
      isVerifyCode: false,
      generatedVerifyCode: null,
      userVerifyCode: null,
      newPrimaryEmail: "",
      invalidNewMail: false,
      isNewPrimaryMailValid: true,
      branchList: [],
      selectedBranch: null,
      studentInformation: {},
    };
  },

  computed: {
    schoolItem() {
      return this.$store.getters.individualSchoolInfoObject;
    },
    schollNameAndIdPrefix() {
      let getDetails = this.$store.getters.individualSchoolInfoObject;
      let schollNamePrefix = getDetails.accountName.slice(0, 3);

      return schollNamePrefix + getDetails.accountId;
    },
    currentAcademicYear() {
      let getDetails = this.$store.getters.getCurrentAcademicYear;

      if (getDetails && getDetails.from) {
        return getDetails.from + " - " + getDetails.to;
      } else {
        return "";
      }
    },
    allBranchList() {
      let data = this.$store.getters.getBranchList;
      return data.filter((x) => x.isActive);
    },
    adminUser() {
      let getDetails = this.$store.getters.userDetail;
      if (getDetails) {
        return getDetails;
      } else {
        return "";
      }
    },
  },
  created() {
    this.initComponent();
  },
  methods: {
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;

        //this.$route.params.id

        this.cloneUserDetails = { ...userData.userDetails };
        if (
          userData.userDetails.profile.name != "Teacher" &&
          userData.userDetails.account
        ) {
          this.schoolDetails = userData.userDetails.account;
          this.$store.dispatch(
            "setIndividualSchoolInfo",
            userData.userDetails.account
          );
        }
        if (this.userDetail.branch) {
          if (
            this.userDetail.profile.name == "School Admin" &&
            this.userDetail.branch.apiName == "main_branch"
          ) {
            this.getBranchList(this.userDetail.account._id);
          } else {
            this.branchList.push({
              name: this.userDetail.branch.name,
              _id: this.userDetail.branch._id,
              isActive: this.userDetail.isActive || true,
            });
            this.$store.dispatch("setBranchList", this.branchList);
          }
        }

        // params.id
        if (this.userDetail.profile.name == "Parent") {
          if (this.$route.params.id) {
            this.getStudentAndSchoolInfo();
          }
        }
        this.selectedBranch = localStorage.getItem("currentBranch");
      }
    },
    getStudentAndSchoolInfo() {
      let userData = secureUI.sessionGet("user");

      ViService.viXGet(
        "/contact/getStudentAndSchoolDetails/parent/portal",
        userData.token
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);

            if (res.data) {
              // const appUrl = process.env.VUE_APP_ENV_CONTEXT == 'production' ? `https://${userData.userDetails.account.domainName}--stage.vidhyaan.com` : 'http://localhost:8070';

              const studentInformationDetails = data;

              const studentProfiles = studentInformationDetails.filter(
                (x) => x.type == "Contact"
              );
              // co
              this.studentInformation = studentProfiles[0];

              // localStorage.setItem(
              //   "activeTab",
              //   `/parent/student/home/${this.studentInformation._id}`
              // );
              // window.location.href = appUrl + `?token=${encodeURIComponent( localStorage.getItem("user") ).trim()}`;

              //  window.location.href = appUrl + '/parent/search/home' + `?token=${encodeURIComponent( localStorage.getItem("user") )}`;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    setRoutes() {
      if (!this.parentButtonToggle) {
        this.$router.push("/parent/search/home");
      } else {
        localStorage.setItem("activeTab", "/parent/home");
        localStorage.setItem("previousTab", "/parent/home");
        this.$router.push("/parent/home");
      }
    },
    resetMailId() {
      this.$bvModal.show("resetMailPopup");
    },
    async checkNewPrimaryEmail() {
      var mailValid =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      let allow = false;

      if (!mailValid.test(this.newPrimaryEmail)) {
        (this.invalidNewMail = true), (this.isNewPrimaryMailValid = true);
        allow = false;
      } else {
        (this.invalidNewMail = false), (allow = true);
      }
    },
   async switchBranch(id) {
    await eventBus.$emit("branchSwitchLoader", id);

   
    await localStorage.setItem("branchSwitch",false);

    localStorage.setItem("currentBranch", id);
    location.reload();
    

      //  this.$store.dispatch('setCurrentAcademicYear', userData.userDetails.account.currentAcademicYear);
      //  localStorage.setItem("currentAcademicYear",userData.userDetails.account.currentAcademicYear._id);
    },
    async primaryMailVerify() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if (this.isNewPrimaryMailValid && !this.invalidNewMail) {
          ViService.viXPost(
            "/user/change/mail/mailVerification/code/sent",
            { email: this.newPrimaryEmail },
            userData.token
          )
            .then((res) => {
              if (res.isSuccess) {
                this.isVerifyCode = true;
                this.generatedVerifyCode = res.data;
                this.$toasted.success(res.message);
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              this.$toasted.error(eData);
            });
        }
      }
    },
    closePrimiaryMailChange() {
      (this.isVerifyCode = false),
        (this.generatedVerifyCode = null),
        (this.userVerifyCode = null),
        (this.newPrimaryEmail = ""),
        (this.invalidNewMail = false),
        (this.isNewPrimaryMailValid = true),
        this.$bvModal.hide("resetMailPopup");
    },
    verifyPrimaryMailCode() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let code = this.generatedVerifyCode.toString();

        if (code == this.userVerifyCode) {
          this.newPrimaryEmail = this.newPrimaryEmail.toLowerCase();

          ViService.viXPost(
            `/user/updatePrimarymail/${this.user._id}`,
            { email: this.newPrimaryEmail },
            userData.token
          )
            .then((res) => {
              if (res.isSuccess) {
                this.user.email = this.newPrimaryEmail;

                this.closePrimiaryMailChange();
                this.closePoup();

                this.$toasted.success(res.message);
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              this.$toasted.error(eData);
            });
        } else {
          this.$toasted.error("Invalid Code");
        }
      }
    },

    async openProfilePopUp() {
      this.initComponent();
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viGetOnly(
          "/common/userDetails?userId=" + this.cloneUserDetails._id
        );

        if (response.isSuccess) {
          this.user._id = response.data._id;
          this.user.firstName = response.data.firstName;
          this.user.lastName = response.data.lastName;
          this.user.userName = response.data.userName;
          this.user.email = response.data.email;
          this.user.phoneNo = response.data.phoneNo;
          this.user.role = response.data.role.name;
          this.user.isActive = response.data.isActive;
        }
      }

      this.$bvModal.show("profilePopUp");
    },
    async getBranchList(accId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viGetOnly(
          "/common/getBranchList?accId=" + accId
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.branchList = resultData;
          this.$store.dispatch("setBranchList", this.branchList);
        }
      }

      //  this.$bvModal.show("profilePopUp");
    },
    closeUserPopUp() {
      this.$bvModal.hide("profilePopUp");
      setTimeout(() => {
        (this.isUserEmailIdAvailable = true),
          (this.user.firstName = ""),
          (this.user.lastName = ""),
          (this.user.email = ""),
          (this.user.role = null),
          (this.user.phoneNo = null);
      }, 100);
    },
    async updateUser() {
      let isFormValid = false;

      await this.$validator.validateAll("userValidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isUserEmailIdAvailable) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let requestBody = {
            user: {
              _id: this.user._id,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              phoneNo: Number(this.user.phoneNo),
            },
            accountDetails: userData.userDetails,
          };
          const response = await ViService.viXPost(
            "/user/updateInternalUser",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            this.closeUserPopUp();
            this.$toasted.success(response.message);
            let getDetails = this.$store.getters.userDetail;
            getDetails.lastName = this.user.lastName;
            getDetails.firstName = this.user.firstName;
            this.$store.dispatch("setUserDetail", getDetails);
          } else {
            this.$toasted.error(response.message);
          }
        }
      }
    },
    async checkUserEmail() {
      let allowDupUser = true;

      if (this.editUserId) {
        // check the current key value is changes
        if (this.user.email == this.prevUserEmailId) {
          allowDupUser = false;
        }
      }

      if (allowDupUser) {
        this.teacher.emailId = this.teacher.emailId.toLowerCase();

        const response = await ViService.viGetOnly(
          `/common/checkApiName?cName=User&email=${this.user.email}`
        );
        if (response.isSuccess) {
          this.isUserEmailIdAvailable = response.isApiNameAvailable;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    logout() {
      ViService.viPost("/auth/logout", { _id: this.userDetail._id })
        .then((res) => {
          if (res.isSuccess) {
            this.$store.dispatch("setIndividualSchoolInfo", {});
            this.$store.dispatch("setCurrentPlanDetail", {});
            this.$store.dispatch("setOurServiceActiveLink", "");
            this.$store.dispatch("setUserDetail", {});

            this.$store.dispatch("setAdmissionObjCOunt", {
              academicYear: 0,
              admission: 0,
              class: 0,
              departMent: 0,
              student: 0,
              teacher: 0,
              schoolType: 0,
              admins: 0,
              section: 0,
              subject: 0,
            });
            this.$store.dispatch("setEnrollObjCOunt", {
              academicYear: 0,
              admission: 0,
              class: 0,
              onlineAdmission: 0,
              isVerified: false,
            });
            this.$store.dispatch("setFeesManagementObjCount", {
              feeType: 0,
              feesPlan: 0,
              adhocFees: 0,
              assignedFeesType: 0,
              discountsAndPenalties: 0,
              feesPlanGenerated: 0,
            });
            this.$store.dispatch("setFetchAdmissionObjCount", false);
            this.$store.dispatch("setFetchFeesObjCount", false);
            this.$store.dispatch("setFetchEnrollObjCount", false);

            localStorage.removeItem("user");
            localStorage.removeItem("vidhyaan-session");
            localStorage.removeItem("currentAcademicYear");

            localStorage.removeItem("currentBranch");
            localStorage.removeItem("messageAction");
            localStorage.removeItem("currentAcademicYear");

            // this.$router.push("/");
            window.location.href = process.env.VUE_APP_REDIRECT_URL;
          } else {
            this.$toasted.error(res.message);
          }
        })
        .catch((e) => {
          this.$toasted.error(eData);
        });

      // location.reload();
    },
    goToParentPortal() {
      localStorage.setItem("activeTab", "/parent/home");
      localStorage.setItem("previousTab", "/parent/home");
      this.$router.push("/parent/home");
    },
    goToParentPortalSearch() {
      this.$router.push("/parent/search/home");
    },
    goToPlanDetails() {
      if (this.schoolDetails.version == "freeVersion") {
        if (this.$route.path !== "/enrollment/setting") {
          localStorage.setItem("activeTab", "/enrollment/setting");
          localStorage.setItem("previousTab", "/enrollment/setting");
          this.$router.push("/enrollment/setting");
        }
      } else {
        if (this.$route.path !== "/admission/admissionSettings") {
          localStorage.setItem("activeTab", "/admission/admissionSettings");
          localStorage.setItem("previousTab", "/admission/admissionSettings");
          this.$router.push("/admission/admissionSettings");
        }
      }
    },
  },
};
</script>

<style lang="scss">
#resetMailPopup .modal-content {
  width: 50% !important; /* Could be more or less, depending on screen size */
}
</style>
