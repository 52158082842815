import Vue from "vue";
import VueRouter from "vue-router";
import ViHome from "../views/ViHome.vue";
import ViAdmissionHome from "../views/ViAdmissionHome.vue";
import NotFoundHandler from "../views/ViErrorHandler.vue";
import AuthGuard from "./auth-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ViHome,
    children: [
      {
          path: 'admission',
          component: ViAdmissionHome,
          children: [
            {
              path: "/admission/home",
              name: "admissionHome",
              component: () => import(/* webpackChunkName: "admissionHome" */ "../components/Admission/ViAdmissionHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/alertsAndNotifications",
              name: "alertsAndNotifications",
              component: () => import(/* webpackChunkName: "alertsAndNotifications" */ "../components/AlertsAndNotifications/ViAlertsAndNotifications"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/admission/academicYear",
              name: "academicYear",
              component: () => import(/* webpackChunkName: "academicYear" */ "../components/Admission/ViAdmissionAcademicYear"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/department",
              name: "department",
              component: () => import(/* webpackChunkName: "department" */ "../components/Admission/ViAdmissionCreateDepartment"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/enroll/schoolType",
              name: "schoolType",
              component: () => import(/* webpackChunkName: "schoolType" */ "../components/Admission/ViAdmissionCreateDepartment"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/teacher",
              name: "teacher",
              component: () => import(/* webpackChunkName: "teacher" */ "../components/Admission/ViAdmissionAddTeacher"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/new",
              name: "admissionNew",
              component: () => import(/* webpackChunkName: "admissionNew" */ "../components/Admission/ViAdmissionNew"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/term/setting",
              name: "termSetting",
              component: () => import(/* webpackChunkName: "termSetting" */ "../components/Terms/ViTerm"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
             {
              path: "/admission/online/home",
              name: "onlineAdmissionHome",
              component: () => import(/* webpackChunkName: "onlineAdmissionHome" */ "../components/OnlineAdmission/ViAdmissionHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/enroll/class",
              name: "ViEnrollClass",
              component: () => import(/* webpackChunkName: "ViEnrollClass" */ "../components/Admission/ViEnrollClass"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/admission/school/profile",
              name: "admissionSchoolProfile",
              component: () => import(/* webpackChunkName: "admissionSchoolProfile" */ "../components/Admission/ViAdmissionSchoolOverview"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            // {
            //   path: "/admission/school/branch",
            //   name: "admissionSchoolBranch",
            //   component: () => import(/* webpackChunkName: "admissionSchoolBranch" */ "../components/Admission/ViAdmissionSchoolBranchOverview"),
            //   meta: {
            //     history: 'School Admin',
            //   },
            //   beforeEnter: AuthGuard,
            // },
            {
              path: "/admission/school/adminProfile",
              name: "admissionSchoolAdminProfile",
              component: () => import(/* webpackChunkName: "admissionSchoolAdminProfile" */ "../components/Admission/ViAdmissionSchoolAdmin"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/school/branch",
              name: "admissionSchoolBranch",
              component: () => import(/* webpackChunkName: "admissionSchoolBranch" */ "../components/Admission/ViAdmissionSchoolBranch"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/school/gallery",
              name: "admissionSchoolGallery",
              component: () => import(/* webpackChunkName: "admissionSchoolGallery" */ "../components/Admission/ViAdmissionGallery"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
          
            {
              path: "/admission/class",
              name: "admissionClass",
              component: () => import(/* webpackChunkName: "admissionClass" */ "../components/Admission/ViAdmissionCreateClass"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/new",
              name: "admissionNew",
              component: () => import(/* webpackChunkName: "admissionNew" */ "../components/Admission/ViAdmissionNew"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/school/preview",
              name: "ViIndividualSchoolView",
              component: () => import(/* webpackChunkName: "ViIndividualSchoolView" */ "../components/Admission/ViIndividualSchoolView"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admin/mySchedule/time/period",
              name: "teacherscheduleperiod",
              component: () => import(/* webpackChunkName: "teacherscheduleperiod" */ "../components/Teacher/ViMySchedule"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/student",
              name: "admissionStudent",
              component: () => import(/* webpackChunkName: "admissionStudent" */ "../components/Admission/ViAdmissionAddStudent"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/student/view/details/:id",
              name: "studentSummary",
              component: () => import(/* webpackChunkName: "studentSummary" */ "../components/Admission/ViStudentSummary"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/student/view/details/:id",
              name: "studentSummary",
              component: () => import(/* webpackChunkName: "studentSummary" */ "../components/Admission/ViStudentSummary"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAttendance"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/student/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAdminViewStudentAndStaffAttendance"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/inbox",
              name: "attendanceRegularization",
              component: () => import(/* webpackChunkName: "promoteStudent" */ "../components/Admission/ViAdmissionAttendanceRegularization"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/promote/students",
              name: "promoteStudent",
              component: () => import(/* webpackChunkName: "promoteStudent" */ "../components/Admission/ViStudentPromote"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/teacher",
              name: "admissionSchoolGallery",
              component: () => import(/* webpackChunkName: "admissionSchoolGallery" */ "../components/Admission/ViAdmissionAddTeacher"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/home",
              name: "feesManagementHome",
              component: () => import(/* webpackChunkName: "feesManagementHome" */ "../components/FeesManagement/ViFeesManagementHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/feesType/:id",
              name: "feesType",
              component: () => import(/* webpackChunkName: "feesType" */ "../components/FeesManagement/ViFeesType"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/feesSummary/:id",
              name: "feesSummary",
              component: () => import(/* webpackChunkName: "feesSummary" */ "../components/FeesManagement/ViFeeSummaryDetails"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
             {
              path: "/feesManagement/createPlan",
              name: "feesType",
              component: () => import(/* webpackChunkName: "feesType" */ "../components/FeesManagement/ViFeesPlan"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/invoice/feePlan",
              name: "invoiceFeePlan",
              component: () => import(/* webpackChunkName: "feesType" */ "../components/FeesManagement/ViInvoiceFeePlan"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/invoice/details/feePlan/students/listView/:id",
              name: "invoiceDetailsFeePlanStudents",
              component: () => import(/* webpackChunkName: "invoiceDetailsFeePlanStudents" */ "../components/FeesManagement/ViInvoiceFeePlanStudentList"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/feesManagement/createFeeType",
              name: "createFeeType",
              component: () => import(/* webpackChunkName: "createFeeType" */ "../components/FeesManagement/ViCreateFeesType"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/paymentGateWay",
              name: "paymentGateWay",
              component: () => import(/* webpackChunkName: "paymentGateWay" */ "../components/FeesManagement/paymentGateWay"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/overallDiscountAndPenalities/:id",
              name: "OverallDiscountAndPenalities",
              component: () => import(/* webpackChunkName: "OverallDiscountAndPenalities" */ "../components/FeesManagement/ViOverallDiscountAndPenalities"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/adhocfees",
              name: "adhocfees",
              component: () => import(/* webpackChunkName: "adhocfees" */ "../components/FeesManagement/ViAdhocfees"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/assignAdhocFess/:id",
              name: "assignAdhocfees",
              component: () => import(/* webpackChunkName: "ViAssignAdhocFess" */ "../components/FeesManagement/ViAssignAdhocFess"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/feesManagement/plan/adhocfee/:id/:type",
              name: "updateAdhocfees",
              component: () => import(/* webpackChunkName: "updateAdhocfees" */ "../components/FeesManagement/ViUpdateAdhocfees"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/admission/dashboard",
              name: "dashboard",
              component: () => import(/* webpackChunkName: "dashboard" */ "../components/Admission/ViAdmissionDashboard"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
             },
            {
              path: "/admission/admissionOnline",
              name: "admissionOnline",
              component: () => import(/* webpackChunkName: "admissionOnline" */ "../components/Admission/ViAdmissionOnline"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/new/enquiry",
              name: "admissionEnquiry",
              component: () => import(/* webpackChunkName: "admissionOnline" */ "../components/Enquiry/ViEnquiryHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/online/request/tc",
              name: "admissionOnlineTc",
              component: () => import(/* webpackChunkName: "admissionOnlineTc" */ "../components/Admission/ViOnlineTc"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
             {
              path: "/academic/academicManagement",
              name: "academicManagement",
              component: () => import(/* webpackChunkName: "academicManagement" */ "../components/Teacher/ViAcademicManagement/ViTeacherAcademicManagementHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/subscription/plan/upgrade",
              name: "upgrade",
              component: () => import(/* webpackChunkName: "upgrade" */ "../views/ViUpgradePlanPricing.vue"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/view/:classId/student/:sectionId",
              name: "viewStudent",
              component: () => import(/* webpackChunkName: "viewStudent" */ "../components/Admission/ViStudentView"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/ImportExp",
              name: "ImportExp",
              component: () => import(/* webpackChunkName: "ImportExp" */ "../components/Admission/ViAdmissionImpexp"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admin/AcademicRenewal/home",
              name: "ImportExp",
              component: () => import(/* webpackChunkName: "AcademicRenewal" */ "../components/AcademicRenewal/AcademicRenewalHomePage"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admin/AcademicRenewal/action/link",
              name: "ImportExp",
              component: () => import(/* webpackChunkName: "AcademicRenewal" */ "../components/AcademicRenewal/AcademicRenewalDetailPage"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/vidhyaan/feature/request",
              name: "featureRequestAdmin",
              component: () => import(/* webpackChunkName: "featureRequestAdmin" */ "../components/Profile/ViFeatureRequest"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/school/admin/home",
              name: "schoolAdminHome",
              component: () => import(/* webpackChunkName: "featureRequestAdmin" */ "../components/Admission/ViAdminHome"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admission/admissionSettings",
              name: "admissionSettings",
              component: () => import(/* webpackChunkName: "admissionSettings" */ "../components/Admission/ViAdmissionSettings"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/enrollment/school",
              name: "enrollSchool",
              component: () => import(/* webpackChunkName: "enrollSchool" */ "../components/EnrollSchool/ViEnrollSchoolView"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/enrollment/setting",
              name: "enrollSchoolSetting",
              component: () => import(/* webpackChunkName: "enrollSchoolSetting" */ "../components/Admission/ViFreePlanSetting"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/parent/home",
              name: "parentHome",
              component: () => import(/* webpackChunkName: "parentHome" */ "../components/Parent/ViParentHome"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/search/home",
              name: "ViHomeHeroSearch",
              component: () => import(/* webpackChunkName: "ViHomeHeroSearch" */ "../components/Parent/ViHomeHeroSearch"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/onlinetc",
              name: "onlineTc",
              component: () => import(/* webpackChunkName: "onlineTc" */ "../components/Parent/ViParentOnlineTC"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/parent/student/home/:id",
              name: "ViStudentHome",
              component: () => import(/* webpackChunkName: "ViStudentHome" */ "../components/Parent/ViStudentHome"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/leave/request/:id",
              name: "ViStudentLeaveRequest",
              component: () => import(/* webpackChunkName: "ViStudentLeaveRequest" */ "../components/Parent/ViStudentLeaveRequest"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/attendance/view/:id",
              name: "ViStudentAttendanceView",
              component: () => import(/* webpackChunkName: "ViStudentAttendanceView" */ "../components/Parent/ViStudentAttendanceView"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/portal/view/details/:id",
              name: "studentSummary",
              component: () => import(/* webpackChunkName: "studentParentPortalSummary" */ "../components/Parent/ViStudentSummary"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/portal/inbox/details/:id",
              name: "studentSummary",
              component: () => import(/* webpackChunkName: "studentParentPortalSummary" */ "../components/Parent/ViInbox"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/ratingAndReview/school/:id",
              name: "ViParentReview",
              component: () => import(/* webpackChunkName: "ViParentReview" */ "../components/Parent/ViParentReview"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/parent/student/paymentHistory/:id",
              name: "paymentHistory",
              component: () => import(/* webpackChunkName: "ViPaymentHistory" */ "../components/Parent/ViPaymentHistory"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/feature/request",
              name: "featureRequestParent",
              component: () => import(/* webpackChunkName: "featureRequestParent" */ "../components/Profile/ViFeatureRequest"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/tc/:id",
              name: "ViParentOnlineTC",
              component: () => import(/* webpackChunkName: "ViParentOnlineTC" */ "../components/Parent/ViParentOnlineTC"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/feePayment/:id",
              name: "ViFeePayment",
              component: () => import(/* webpackChunkName: "ViFeePayment" */ "../components/Parent/ViFeePayment"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/alerts/:id",
              name: "ViAlertsAndNotifications",
              component: () => import(/* webpackChunkName: "ViAlertsAndNotifications" */ "../components/Parent/ViAlertsAndNotifications"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/parent/student/freePortal/view/admission/details/:id",
              name: "studentSummary",
              component: () => import(/* webpackChunkName: "studentParentPortalSummary" */ "../components/Parent/ViStudentFreeSchoolSummary"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/student/academicManagement/:id",
              name: "studentAcademicManagement",
              component: () => import(/* webpackChunkName: "academicManagement" */ "../components/Parent/ViAcademicManagement/ViTeacherAcademicManagementHome"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/parent/admission",
              name: "admission",
              component: () => import(/* webpackChunkName: "admission" */ "../components/Parent/ViParentAdmission"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/parent/search-school",
              name: "parent-search",
              component: () => import(/* webpackChunkName: "parent-search" */ "../components/Parent/ViParentSchools"),
              meta: {
                history: 'Parent',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/home",
              name: "teacher-home",
              component: () => import(/* webpackChunkName: "teacher-home" */ "../components/Teacher/ViTeacherHome"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
           {
              path: "/teacher/examSchedule",
              name: "teacher-home",
              component: () => import(/* webpackChunkName: "teacher-home" */ "../components/Teacher/ViTeacherExamSchedule.vue"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/admin/examSchedule",
              name: "teacher-home",
              component: () => import(/* webpackChunkName: "teacher-home" */ "../components/Teacher/ViTeacherExamSchedule.vue"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/action/promote/students",
              name: "promoteStudent",
              component: () => import(/* webpackChunkName: "promoteStudent" */ "../components/Admission/ViStudentPromote"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/teacher/academicManagement",
              name: "academicManagement",
              component: () => import(/* webpackChunkName: "academicManagement" */ "../components/Teacher/ViAcademicManagement/ViTeacherAcademicManagementHome"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/schedule/time/period",
              name: "teacherscheduleperiod",
              component: () => import(/* webpackChunkName: "teacherscheduleperiod" */ "../components/Teacher/ViMySchedule"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/inbox",
              name: "academicManagement",
              component: () => import(/* webpackChunkName: "academicManagement" */ "../components/Inbox/ViInboxHome"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/view/student/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAdminViewStudentAndStaffAttendance"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/overview/:classId/students/:sectionId",
              name: "viewStudent",
              component: () => import(/* webpackChunkName: "viewStudent" */ "../components/Admission/ViStudentView"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            {
              path: "/teacher/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAttendance"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard
            },
            {
              path: "/admin/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAttendance"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard
            },
            {
              path: "/teacher/leave/management/view",
              name: "leaveManagement",
              component: () => import(/* webpackChunkName: "leaveManagement" */ "../components/ViStaffLeaveRequest"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard
            },
            {
              path: "/feesSummary/invoice/list",
              name: "leaveManagement",
              component: () => import(/* webpackChunkName: "feesSummaryinvoicelist" */ "../components/Teacher/ViInvoiceFeePlan"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard
            },
            {
              path: "/feesSummary/invoice/details/feePlan/students/listView/:id",
              name: "invoiceDetailsFeePlanStudents",
              component: () => import(/* webpackChunkName: "feesSummaryTeacher" */ "../components/Teacher/ViInvoiceFeePlanStudentList"),
              meta: {
                history: 'Teacher',
              },
              beforeEnter: AuthGuard,
            },
            
            {
              path: "/teacher/admin/leave/management/view",
              name: "leaveManagement",
              component: () => import(/* webpackChunkName: "leaveManagement" */ "../components/ViStaffLeaveRequest"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard
            },
            {
              path: "/attendance/management",
              name: "AttendanceManagement",
              component: () => import(/* webpackChunkName: "AttendanceManagement" */ "../components/ViAttendance"),
              meta: {
                history: 'School Admin',
              },
              beforeEnter: AuthGuard
            }
          ]
      },
     
      {
        path: "/register/jobseeker",
        name: "register-jobseeker",
        component: () =>
          import(
            /* webpackChunkName: "jobseeker" */ "../components/Profile/ViRegisterJobSeeker.vue"
          ),
      },
      {
        path: "/register/parent",
        name: "register-parent",
        component: () =>
          import(
            /* webpackChunkName: "parent" */ "../components/Profile/ViRegisterParentSelf.vue"
          ),
      },
      {
        path: "/register/school",
        name: "register-school",
        component: () =>
          import(
            /* webpackChunkName: "school" */ "../components/Profile/ViRegisterSchoolIndividual.vue"
          ),
      },
      
      {
        path: "/search-schools",
        name: "schools",
        component: () =>
          import(
            /* webpackChunkName: "school-list" */ "../components/School/ViSchools.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../components/Profile/ViLogin.vue"
      ),
  },
  
  {
    path: "/search-school/overview",
    name: "register-school",
    component: () =>
      import(
        /* webpackChunkName: "search-scdddddhool" */ "../components/Profile/CommonSchoolSearch.vue"
      ),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../components/Profile/ViForgotPassword.vue"
      ),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../components/Profile/ViResetPassword.vue"
      ),
  },
  {
    path: "/invitaion-accept",
    name: "invitaion",
    component: () =>
      import(
        /* webpackChunkName: "ViAcceptInvation" */ "../components/Profile/ViAcceptInvation.vue"
      ),
  },
  
  {
    path: "/pricingView",
    name: "pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/ViPricing.vue"),
  },
  {
    path: "/admin-panel",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "pricing" */ "../components/Admin/ViAdmin.vue"
      ),
  },
  {
    path: "/grand-login/validate",
    name: "grandLogin",
    component: () =>
      import(
        /* webpackChunkName: "pricing" */ "../components/Admin/ViGrandLogin.vue"
      ),
  },
  {
    path: "/paymentConfirmation",
    name: "payment",
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../views/ViPaymentCompletd.vue"
      ),
  },
  {
    path: "/subscribe/enrollSchool",
    name: "enrollSchool",
    component: () =>
      import(
        /* webpackChunkName: "enrollSchool" */ "../views/ViEnrollPlan.vue"
      ),
  },
  { path: "*", component: NotFoundHandler },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {

  const IsItABackButton = window.popStateDetected;
  window.popStateDetected = false;
  if (IsItABackButton && from.meta.someLogica) {
    next(false);
    return "";
  }
  next();
});
export default router;
