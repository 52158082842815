import { io } from "socket.io-client";

export let socket = io();

export let setupSocketConnection = () => {
  let url = process.env.VUE_APP_ENV_CONTEXT == 'production' ? `${window.location.protocol}//${window.location.hostname}` : 'http://localhost:3000'

  //socket = io(url,{ transports: ['websocket'], upgrade: false,autoConnect: false });

  // socket = io(url,{ withCredentials: false });
  socket = io(url);

  socket.on("connect_error", err => {
    console.log("connect_error",err)
    if (err.message === "invalid userId") {
      localStorage.removeItem("vidhyaan-session");
    }
  });

  socket.on("session", sessionID => {
   // if (!localStorage.getItem("vidhyaan-session")) {
      localStorage.setItem("vidhyaan-session", sessionID);
    //}
  });
};
