function apiErrorLog(e) {
  if (e.message === "Network Error") {
    return "<span class='msgText'> - Please check internet connection</span>";
  }
  if (e.message != "Request_Cancel") {
    if (e.code == "ECONNABORTED") {
      return "<span class='msgText'> - Connection time out</span>";
    }
    if (e.response && e.response.status === 500) {
      return "<span class='msgText'> - Please retry the action as server encountered unexpected error</span>";
    }
    if (e.response && e.response.status === 504) {
      return "<span class='msgText'> - Please retry the action as server encountered timeout error</span>";
    }
    if (typeof e.message != "object") {
      return `<span class='msgText'> - ${e.message}</span>`;
    }
  }
  return null;
}
export default {
  apiErrorLog,
};
