import { format, utcToZonedTime } from "date-fns-tz";

export const dateFilter = value => {
    if (value) {
        let date = new Date(value);
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    } else {
        return '';
    }
};

export const dateFormatFilter = value => {
    if (value) {
        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
    } else {
        return '';
    }
};
export const dateAndTimeFormatFilter = value => {
    if (value) {
        const currentDate = new Date(value); // current date

        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}  ${currentDate.getUTCHours()}:${currentDate.getUTCMinutes()}:${currentDate.getUTCSeconds()}`;
        
    } else {
        return '';
    }
};

/** Common method to handle the date time format */
export const getFormattedDateTime = value => {
    if (value) {
      return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),"dd-MMM-yyyy HH:mm");
    } else {
      return "";
    }
  };
        
export const currencyDecimalFilter = value => {
    if (typeof value == 'number') {
        return Number(value).toLocaleString();
    } else {
        return 0;
    }
};