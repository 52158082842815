import Vue from "vue";
import randomAlphaNumeric from "./randomAlphaNumeric";

function sessionSet(key, obj) {
  let sessionObj = JSON.stringify(obj);
  let scID = randomAlphaNumeric.randomChars();
  const encryptedText = Vue.CryptoJS.AES.encrypt(sessionObj, scID).toString();
  localStorage.setItem(key, encryptedText + scID);
}
function sessionGet(key) {
  try {
    let data = localStorage.getItem(key);
    if (data) {
      let scID = randomAlphaNumeric.randomExract(data);
      data = randomAlphaNumeric.randomRemove(data);
      const decryptedText = Vue.CryptoJS.AES.decrypt(data, scID).toString(
        Vue.CryptoJS.enc.Utf8
      );
      const json = JSON.parse(decryptedText);
      return json;
    } else {
      return null;
    }
  } catch(err) {
    console.log('in session get error --> ', err);
  }
}
function secureGet(data) {
  let scID = randomAlphaNumeric.randomExract(data);
  data = randomAlphaNumeric.randomRemove(data);
  const decryptedText = Vue.CryptoJS.AES.decrypt(data, scID).toString(
    Vue.CryptoJS.enc.Utf8
  );
  const json = JSON.parse(decryptedText);
  return json;
}
function sessionRemove() {
  localStorage.removeItem("user");
}
export default {
  secureGet,
  sessionSet,
  sessionRemove,
  sessionGet,
};
