import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    terms: {},
    subjects: [],
    schoolList: [],
    schoolInfo: [],
    userDetails: {},
    individualSchoolInfo: {},
    currentPlanDetails: [],
    currentTab: '',
    currentAcademicYear: {},
    ourServiceActiveLink: '',
    classAndSectionList: [],
    userTeacherList: [],
    branchList: [],
    workingDays: [],
    tabPermission: [],
    subscriptionQuantity: 1,
    fetchDataAdmissionObjCounts: false,
    fetchDataEnrollObjCounts: false,
    fetchDataFeesObjCounts: false,
    admissionObjCounts: {
      academicYear: 0,
      admission: 0,
      class: 0,
      departMent: 0,
      student: 0,
      term: 0,
        grade: 0,
      teacher: 0,
      schoolType: 0,
      admins: 0,
      section: 0,
      subject: 0

    },
    enrollObjCounts: {
    
      academicYear: 0,
      admission: 0,
      class: 0,
      onlineAdmission: 0,
      isVerified: false
    },
    feesManagementObjCounts: {
      feeType : 0,
      feesPlan : 0,
      adhocFees : 0,
      assignedFeesType : 0,
      discountsAndPenalties : 0,
      feesPlanGenerated: 0
    }
  },
  mutations: {
    SET_SUBJECTS(state, payload) {
      state.subjects = payload.subjects || [];
    },
    SET_TERMS(state, payload) {
      if( payload.data && payload.data.class ) {
        state.terms[ payload.data.class ] = payload.data.terms || [];
      }
    },
    SET_USER_DETAIL(state, payload) {
      state.userDetails = payload;
    },
    SET_SCHOOL_LIST(state, payload) {
      state.schoolList = payload;
    },
    SET_SCHOOL_INFO(state, payload) {
      state.schoolInfo = payload;
    },
    SET_INDIVIDUAL_SCHOOL_INFO(state, payload) {
      state.individualSchoolInfo = payload;
    },
    SET_CURRENT_PLAN_DETAILS(state, payload) {
      state.currentPlanDetails = payload;
    },
    SET_TAB_PERMISSION(state, payload) {
      state.tabPermission = payload;
    },
    SET_QUANTITY(state, payload) {
      state.subscriptionQuantity = payload;
    },
    
    SET_CURRENT_TAB(state, payload) {
      state.currentTab = payload;
    },
    SET_CURRENT_ACADEMIC_YEAR(state, payload) {
      state.currentAcademicYear = payload;
    },
    SET_CLASS_SECTIONS(state, payload) {
      state.classAndSectionList = payload;
    },
    SET_USER_TEACHER_LIST(state, payload) {
      state.userTeacherList = payload;
    },
    SET_BRANCH_LIST(state, payload) {
      state.branchList = payload;
    },
    SET_WORKING_DAYS(state, payload) {
      state.workingDays = payload;
    },
    SET_OUR_SERVICE_ACTIVE_LINK(state, payload) {
      state.ourServiceActiveLink = payload;
    },
    SET_ADMISSION_OBJ_COUNTS(state, payload) {
      state.admissionObjCounts = payload;
    },
    SET_ENROLL_OBJ_COUNTS(state, payload) {
      state.enrollObjCounts = payload;
    },
    SET_FEESMANAGEMENT_OBJ_COUNTS(state, payload) {
      state.feesManagementObjCounts = payload;
    },
    SET_FETCH_ADMISSION_OBJ_COUNTS(state, payload) {
      state.fetchDataAdmissionObjCounts = payload;
    },
    SET_FETCH_ENROLL_OBJ_COUNTS(state, payload) {
      state.fetchDataEnrollObjCounts = payload;
    },
    SET_FETCH_FEESMANAGEMENT_OBJ_COUNTS(state, payload) {
      state.fetchDataFeesObjCounts = payload;
    }
  },
  actions: {
    setUserDetail(context, payload) {
      context.commit("SET_USER_DETAIL", payload);
    },
    setSchoolList(context, payload) {
      context.commit("SET_SCHOOL_LIST", payload);
    },
    setSchoolInfo(context, payload) {
      context.commit("SET_SCHOOL_INFO", payload);
    },
    setIndividualSchoolInfo(context, payload) {
      context.commit("SET_INDIVIDUAL_SCHOOL_INFO", payload);
    },
    setCurrentPlanDetail(context, payload) {
      context.commit("SET_CURRENT_PLAN_DETAILS", payload);
    },
    setTabPermission(context, payload) {
      context.commit("SET_TAB_PERMISSION", payload);
    },
    setSubscriptionQuantity(context, payload) {
      context.commit("SET_QUANTITY", payload);
    },
    
    setCurrentAcademicYear(context, payload) {
      context.commit("SET_CURRENT_ACADEMIC_YEAR", payload);
    },
    
    setCurrentTab(context, payload) {
      context.commit("SET_CURRENT_TAB", payload);
    },
    setClassAndSectionList(context, payload) {
      context.commit("SET_CLASS_SECTIONS", payload);
    },
    setUserTeacherList(context, payload) {
      context.commit("SET_USER_TEACHER_LIST", payload);
    },
    setBranchList(context, payload) {
      context.commit("SET_BRANCH_LIST", payload);
    },
    setWorkingDays(context, payload) {
      context.commit("SET_WORKING_DAYS", payload);
    },
    setOurServiceActiveLink(context, payload) {
      context.commit("SET_OUR_SERVICE_ACTIVE_LINK", payload);
    },
  
    setAdmissionObjCOunt(context, payload) {
      context.commit("SET_ADMISSION_OBJ_COUNTS", payload);
    },
    setEnrollObjCOunt(context, payload) {
      context.commit("SET_ENROLL_OBJ_COUNTS", payload);
    },
    setFeesManagementObjCount(context, payload) {
      context.commit("SET_FEESMANAGEMENT_OBJ_COUNTS", payload);
    },
    setFetchAdmissionObjCount(context, payload) {
      context.commit("SET_FETCH_ADMISSION_OBJ_COUNTS", payload);
    },
    setFetchEnrollObjCount(context, payload) {
      context.commit("SET_FETCH_ENROLL_OBJ_COUNTS", payload);
    },
    setFetchFeesObjCount(context, payload) {
      context.commit("SET_FETCH_FEESMANAGEMENT_OBJ_COUNTS", payload);
    }
    
  },
  getters: {
    userDetail(state) {
      return state.userDetails;
    },
    schoolListObject(state) {
      return state.schoolList;
    },
    SchoolInfoObject(state) {
      return state.schoolInfo;
    },
    individualSchoolInfoObject(state) {
      return state.individualSchoolInfo;
    },
    currentPlanDetail(state) {
      return state.currentPlanDetails;
    },
    getCurrentTab(state) {
      return state.currentTab;
    },
    getCurrentAcademicYear(state) {
      return state.currentAcademicYear;
    },    
    getOurServiceActiveLink(state) {
      return state.ourServiceActiveLink;
    },
    getAdmissionObjCounts(state) {
      return state.admissionObjCounts;
    },
    getEnrollObjCounts(state) {
      return state.enrollObjCounts;
    },
    getFeesManagementObjCount(state) {
      return state.feesManagementObjCounts;
    },

    getFetchAdmissionObjCounts(state) {
      return state.fetchDataAdmissionObjCounts;
    },
    getFetchEnrollObjCounts(state) {
      return state.fetchDataEnrollObjCounts;
    },
    getFetchFeesManagementObjCount(state) {
      return state.fetchDataFeesObjCounts;
    },
    getClassAndSectionList(state){
      return state.classAndSectionList;
    },
    getUserTeacherList(state){
      return state.userTeacherList;
    },
    getBranchList(state){
      return state.branchList;
    },
    getWorkingDays(state){
      return state.workingDays;
    },
    getTabPermission(state){
      return state.tabPermission;
    },
    getSubscriptionQuantity(state){
      return state.subscriptionQuantity;
    },
    
    
    
    
    
    

  },

});
